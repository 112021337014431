// Paths Blöd?

import { miUser } from "./MFirebase";

// prettier-ignore
export const basePaths = {
  company  : "companies",
  requests : "requests",
  user     : "users",
  dev      : "dev",
};

/**
 * Get paths for the Firebase Collections by CompanyID.
 *
 * Usage:
 * -> Set compID in Firebase
 *    e.g. this.paths = getPaths(compID)
 *    (better way? 🤔)
 *
 * -> storeHelper.get(this.paths[key](id))     -(fullType.key)
 * -> storeHelper.listenTo(this.paths[dbKey])  -(fullType.db)
 *
 * @param {String | Number} compID
 * @returns Paths for Firebase Collection
 */
// prettier-ignore
export const getPaths = (compID) => {
  const company       = `/companies/${compID}`;
  const user          = `/users/${miUser.uid || miUser.id}`;
    return {
      // Basics
      miUser          : user,
      company         : company,
      
      // Types
      requests        : `${company}/requests/`,
      projects        : `${company}/projects/`,
      customers       : `${company}/customers/`,
      suppliers       : `${company}/suppliers/`,
      basedatas       : `${company}/basedatas/`,
      docs            : `${company}/docs/`,
      events          : `${company}/events/`,
      notes           : `${company}/notes/`,
      todos           : `${company}/todos/`,
      users           : `${company}/users/`,
      todoGroups      : `${company}/todoGroups/`,

      // Docs
      basedataGroups  : `${company}/extras/basedataGroups`,
      userGroups      : `${company}/extras/userGroups`,
      
      // Single with ID
      request         : (requestID)   => `/requests/${requestID}`,
      project         : (pID)         => `${company}/projects/${pID}`,
      customer        : (cID)         => `${company}/customers/${cID}`,
      supplier        : (sID)         => `${company}/suppliers/${sID}`,
      basedata        : (bdID)        => `${company}/basedatas/${bdID}`,
      user            : (uID)         => `${company}/users/${uID}`,
      event           : (eID)         => `${company}/events/${eID}`,
      todo            : (tdID)        => `${company}/todos/${tdID}`,
      todoGroup       : (tgID)        => `${company}/todoGroups/${tgID}`,
      doc             : (bID)         => `${company}/docs/${bID}`,
      note            : (noteID)      => `${company}/notes/${noteID}`,
    };
};

// Paths
export const devVersionPath = "/dev/SubVersion";
export const devNewVersionPath = "/dev/NewVersion";
export const devFeedbackPath = "/dev/data/feedbacks/";

//
// OLD but Gold?
//

// const basePath = (type, id) => {
//   let path = "companies/" + currentUser.companyid;
//   if (type) path += "/" + type;
//   if (id) path += "/" + id;
//   return path;
// };

// const userPath = () => {
//   let path = "/users/" + currentUser.uid;
//   return path;
// };
