<template>
  <div>
    <loading-page v-if="!loadingComplete" />

    <main-page v-else-if="!isSetup" />

    <v-app class="app" v-else>
      <v-main>
        <router-view class="setup-router" name="setup" />
      </v-main>
    </v-app>
    <company-dialog v-if="getDialog('company').show" />
  </div>
</template>

<script>
import { MODES } from "./store";
import { mapActions, mapGetters, mapState } from "vuex";
import { userDefaults } from "./types/user/MiUser";

import LoadingPage from "./views/pages/LoadingPage.vue";
import MainPage from "./views/app/MainPage.vue";
import CompanyDialog from "./types/company/dialogs/CompanyDialog.vue";

export const appListeners = {
  user: null,
  userData: null,
  company: null,
};

export default {
  components: {
    MainPage,
    LoadingPage,
    CompanyDialog,
  },

  data() {
    return {
      requests: [],
      isDev: false,

      modes: MODES,

      initSetUser: false,
      initSetComp: false,
      showUsers: false,
    };
  },

  computed: {
    ...mapState(["currentTime"]),
    ...mapGetters([
      // Context
      "getContext",
      "hasContext",
      "hasContextFor",
      "emptyContext",

      // Setup
      "getSetupState",
      "setupCompleted",
    ]),
    ...mapGetters("miuser", ["getUser", "img", "uLoaded"]),
    ...mapGetters("company", ["getCompany", "getIsAdmin", "cLoaded"]),
    ...mapGetters("appbar", ["getHideSide", "isSetup"]),
    ...mapGetters("dialog", ["getDialog"]),

    user() {
      return this.getUser;
    },

    mode: {
      get() {
        return this.getSetupState;
      },
      set(value) {
        this.setMode(value);
      },
    },

    loadingComplete() {
      return this.uLoaded && this.cLoaded;
    },

    allSet() {
      return this.getUser && this.getCompany;
    },
  },

  mounted() {
    setInterval(async () => {
      this.updateCurrentTime(Date.now());
    }, 1000);

    this.listenUser();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },

  methods: {
    ...mapActions("lang", ["setLocale"]),
    ...mapActions("dialog", ["showDialog"]),
    ...mapActions("worker", ["listenToAllWorkers"]),
    ...mapActions("project", ["setProjectID"]),
    ...mapActions("basedata", ["fetchBasedataGroups"]),
    ...mapActions([
      "updateCurrentTime",
      "setWindowSize",
      "fetchBookmarks",
      "fetchEvents",
      "fetchUsers",
      "fetchCounts",
      "fetchUser",
    ]),
    ...mapActions("appbar", ["setHideFrame"]),
    ...mapActions("favorites", ["setFavorites"]),
    ...mapActions("miuser", [
      "setUser",
      "setUserDefault",
      "listenUser",
      "setULoaded",
    ]),
    ...mapActions("company", [
      "listenCompany",
      "getCompanies",
      "setCompanyID",
      "setCLoaded",
    ]),

    onResize() {
      const { innerWidth, innerHeight } = window;
      this.setWindowSize({
        width: innerWidth,
        height: innerHeight,
      });
    },

    goTo(path) {
      this.$router
        .push({
          path: path,
        })
        .catch(() => {});
    },

    allSetup() {
      this.$firebase.getSubmittedVersion().then((it) => {
        // console.log("New Version!!", it);
        this.$store.state.newVersion = it;
        if (localStorage.seenVersion != it.version)
          this.$store.state.dialogs.newVersion.show = true;
      });
      this.setup = true;
    },

    async setCustoms(company) {
      if (company.custom)
        for (const key in company.custom) {
          if (Object.hasOwnProperty.call(company.custom, key)) {
            this.$store.state[key] = company.custom[key];
          }
        }
    },
  },

  watch: {
    $route: {
      deep: true,
      handler(route) {
        console.log("ROUTE", route);
        if (route.path == "/") {
          if (this.getCompany)
            this.$router.push({
              name: "Company",
              params: {
                compId: this.getCompany.id,
              },
            });
          this.$router.push("/login");
        }
      },
    },
    "$route.params": {
      deep: true,
      handler({ compId }) {
        if (!this.getUser) return;
        if (compId && this.getCompany?.id != compId) {
          this.setCompanyID(compId);
        }
      },
    },

    getUser: function (user) {
      console.log("User Changed", user);
      if (!user) {
        this.setULoaded(true);
        this.setCLoaded(true);
        this.setLocale("de");
        return;
      }

      user.isAdmin = this.getIsAdmin(user.id);

      if (!this.initSetUser && !this.getCompany) {
        if (user.defaults) this.setCompanyID(user.defaults.company);
        else this.setCLoaded(true);
        if (user.lang) this.setLocale(user.lang);
      }

      if (user.styles.currentTheme) {
        this.$vuetify.theme.themes.dark = {
          ...this.$vuetify.theme.themes.dark,
          ...user.styles.currentTheme,
        };
        this.$vuetify.theme.themes.light = {
          ...this.$vuetify.theme.themes.light,
          ...user.styles.currentTheme,
        };
        this.$vuetify.theme.applyTheme();
      }

      this.initSetUser = true;
      this.setULoaded(true);
    },

    getCompany: function (company) {
      console.log("Company Changed", company);
      this.setCLoaded(true);
      if (!company) return;

      this.fetchBasedataGroups();

      if (this.getUser) {
        this.getUser.isAdmin = this.getIsAdmin(this.getUser.id);

        if (company?.favorites && company.favorites[this.getUser.id])
          this.setFavorites(company.favorites[this.getUser.id]);

        if (!this.getUser.defaults && !this.getUser.defaults.company)
          this.setUserDefault(userDefaults.company(company.id));

        this.listenToAllWorkers();
      }

      this.initSetComp = true;
    },

    allSet(value, old) {
      if (value == old) return;
      console.log("AllSet", value);
      if (!value) this.goTo("/login");
      // else this.goTo("/c/" + this.getCompany.id);
    },
  },
};
</script>

<style scoped lang="scss">
.setup-router {
  width: 100%;
  height: 100%;
  background-color: var(--v-primary-darken1) !important;
}

.app {
  @media print {
    * {
      margin: 0;
      padding: 0;
    }
  }

  .users {
    padding: 0;
    border-radius: 2rem;
    background-color: rgba(245, 245, 245, 0.1);
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
    overflow: hidden;

    .user-btn {
      padding-top: 2vh;
      padding-bottom: 1vh;
      border-radius: 0;
      background-color: rgba(245, 245, 245, 0.01);
      // border: 1px solid white;
    }

    .end-btn {
      width: 100%;
      padding-top: 1vh;
      padding-bottom: 2vh;
      border-radius: 0;
      background-color: rgba(245, 245, 245, 0.01);
    }

    .user-cont {
      width: 90%;
      gap: 4px;

      .user {
        border-radius: 1rem;
        padding: 4px;
        text-align: center;
        background-color: rgba(245, 245, 245, 0.2);
      }
    }
  }

  .mi-actions {
    flex-shrink: 0; // this prevents shrinking of action bar
    padding: 2vh 2.5vw;
  }

  .appbar-right {
    padding: 0.25rem 0;
    // background-color: rgba(245, 245, 245, 0.075);

    .mcont {
      padding: 0.5rem 0.5rem 0.5rem 0.75rem;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      background-color: rgba(245, 245, 245, 0.2);
      // background-color: var(--v-accent-darken1);
    }
  }

  .main-footer {
    position: fixed;
    bottom: 0;
    left: 50%;
    translate: transformX(-50%);
    width: 4rem;
    height: 1rem !important;
    background-color: whitesmoke;
  }
}

.loading-page {
  width: 100vw;
  height: 100vh;
  background-color: var(--v-primary-darken2);
}
</style>

<style lang="scss">
@import "./assets/basics.scss";
@import "./assets/globals.scss";

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}

* {
  // overflow: hidden !important;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow: hidden;
}

.only-print {
  display: none;
  @media print {
    display: unset;
  }
}

.hide-print {
  // display: unset;
  @media print {
    display: none !important;
  }
}

.dashed {
  border: 1px dashed gray;
  border-radius: 2rem;
  padding: 1rem 1rem;
}

.coming-soon {
  opacity: 0.4 !important;
  position: relative;
}

.coming-soon-message {
  position: absolute;
  opacity: 0.8;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem 2rem;
  border: 1px solid var(--v-primary-base);
  border-radius: 2rem;
  background-color: white;
}

*::-webkit-scrollbar {
  width: 0.33rem;
  height: 4px;
  position: fixed;
  right: 4px;
  margin-right: 1rem;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.4);
  background-color: transparent;
  background: transparent;
  border-radius: 10px;
  margin: 2.5vw 1rem;
}

*::-webkit-scrollbar-thumb {
  background-image: -webkit-linear-gradient(
    100deg,
    var(--v-primary-base) 0%,
    var(--v-accent-base) 100%
  );
  border-radius: 10px;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

@media screen and (max-width: 800px) {
  .v-slide-group__prev {
    display: none !important;
  }
}
</style>
