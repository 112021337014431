import Struct from "./datas/company.json";
//
import Location from "../location/location";
import { cloneDeep } from "lodash";

export default class Company {
  constructor(company) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (company) {
      // Converter from old
      if (Array.isArray(company.tags)) {
        company.tags = {
          projects: company.tags,
        };
      }
      Object.assign(this, company);
      this.location = new Location(this.location);
    }
  }

  // DB
  toSaveable() {
    let item = { ...cloneDeep(this) };
    delete item.type;
    if (item.location.toSaveable) item.location = item.location.toSaveable();
    return item;
  }
}
