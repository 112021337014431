export default {
  app: "mdi-sun-compass",
  dashboard: "mdi-view-dashboard",
  search: "mdi-magnify",

  project: "mdi-clipboard-list-outline",
  openProject: "mdi-clipboard-clock-outline",
  customer: "mdi-account-group",
  supplier: "mdi-factory",
  user: "mdi-account",
  worker: "mdi-account-hard-hat",
  basedata: "mdi-package",
  basedataGroup: "mdi-folder-multiple",
  company: "mdi-domain",
  contact: "mdi-emoticon",
  contactm: "mdi-face-man",
  contactw: "mdi-face-woman",
  contacts: "mdi-account-multiple",

  doc: "mdi-file-sign",
  docs: "mdi-text-box-multiple",

  location: "mdi-map-marker",
  locations: "mdi-map-marker-multiple",
  calendar: "mdi-calendar",
  events: "mdi-calendar",
  event: "mdi-calendar-star-four-points",
  imgs: "mdi-image-area",
  cloud: "mdi-cloud",
  upload: "mdi-upload",
  todos: "mdi-format-list-checkbox",
  todo: "mdi-checkbox-marked-circle-outline",
  todouf: "mdi-checkbox-blank-circle-outline",
  delivery: "mdi-truck-delivery",
  pay: "mdi-cash",
  payLocation: "mdi-cash-marker",
  phone: "mdi-phone",
  mail: "mdi-email",
  note: "mdi-notebook",
  goal: "mdi-flag-checkered",
  form: "mdi-list-box-outline",
};
