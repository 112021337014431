import firebase from "../../db/firebase/MFirebase";
import { limit, where, orderBy } from "firebase/firestore";
import Customer from "../../types/customer/customer";

let listener, pListener, nListener;
const initState = {
  cID: undefined,
  customer: undefined,
  customerEdit: { title: "", name: "", nr: "", web: "", desc: "" },
  projects: [],
  notes: [],
  loaded: {
    projects: false,
    notes: false,
  },
};

export default {
  namespaced: true,
  name: "customer",

  state: {
    ...initState,
  },

  mutations: {
    setCustomer(state, customer) {
      state.cID = customer?.id || null;
      state.customer = customer;
    },

    setProjects(state, projects) {
      state.projects = projects;
    },

    setNotes(state, notes) {
      state.notes = notes;
    },

    removeNote(state, noteId) {
      state.notes = state.notes.filter((note) => note.id !== noteId);
    },

    setCustomerEdit(state, customer) {
      if (customer) state.customerEdit = new Customer(customer);
      else state.customerEdit = { ...initState.customerEdit };
    },
    // cEdit.title = "sjafkasof"

    setLoaded(state, { key, value }) {
      state.loaded[ key ] = value;
    },

    resetState(state) {
      Object.assign(state, initState);
    },
  },

  actions: {
    async setCustomerID({ dispatch }, id) {
      if (listener) listener();

      listener = firebase.listenToOne(
        "customer",
        id,
        (found) => {
          dispatch("setCustomer", found);
        },
        (err) => {
          console.error("Customer Module", err);
        }
      );
    },

    setCustomer({ commit, dispatch }, customer) {
      commit("setCustomer", customer);
      commit("setCustomerEdit", customer);
      if (customer?.id) {
        dispatch("fetchEntries");
      }
    },

    fetchEntries({ state, commit }) {
      if (pListener) pListener();
      if (nListener) nListener();

      commit("setLoaded", { key: "projects", value: false });
      commit("setLoaded", { key: "notes", value: false });

      if (state.cID) {
        console.log("Listen to Customer Projects", state.cID);
        pListener = firebase.listenTo(
          "projects",
          (found) => {
            console.log("LISTEN CUSTOMER RETURN", found);
            commit("setProjects", found);
            commit("setLoaded", { key: "projects", value: true });
          },
          [
            where("customer.id", "==", state.cID),
            orderBy("updated", "desc"),
            limit(5),
          ],
          (err) => console.log(err)
        );

        console.log("Listen to Customer Notes", state.cID);
        nListener = firebase.listenTo(
          "notes",
          (found) => {
            console.log("Listener Customer Notes found", found);
            commit("setNotes", found);
            commit("setLoaded", { key: "notes", value: true });
          },
          [ where("cID", "==", state.cID), orderBy("updated", "desc"), limit(5) ],
          (err) => console.log(err)
        );
      }
    },

    async saveItem({ commit, state }) {
      try {
        // Save the edited customer data to Firebase
        await firebase.customer.set(
          state.customerEdit.toSaveable
            ? state.customerEdit.toSaveable()
            : state.customerEdit
        );

        // Commit the mutation to update the customer in the state
        commit("setCustomer", state.customerEdit);
      } catch (err) {
        console.error("Customer Module", err);
      }
    },

    updateEdit({ commit, state }, { key, value }) {
      if (key in state.customerEdit) {
        commit("setCustomerEdit", {
          ...state.customerEdit,
          [ key ]: value,
        });
      }
    },

    // ArrayItem
    updateItem({ commit, state }, { key, index, item }) {
      const updatedItems = state.customerEdit[ key ].map((data, i) =>
        i === index ? item : data
      );
      commit("setCustomerEdit", {
        ...state.customerEdit,
        [ key ]: updatedItems,
      });
    },

    addLocation({ commit, dispatch, state }, location) {
      // Update the customerEdit's locations with the new location
      const updatedLocations = [ ...state.customerEdit.locations, location ];

      // Commit the mutation to update the locations in the customerEdit
      commit("setCustomer", {
        ...state.customerEdit,
        locations: updatedLocations,
      });

      dispatch("update", { locations: updatedLocations });
    },

    deleteLocation({ commit, dispatch, state }, location) {
      // Update the customerEdit's locations by removing the deleted location
      const updatedLocations = state.customerEdit.locations.filter(
        (loc) => loc.id !== location.id
      );

      // Commit the mutation to update the locations in the customerEdit
      commit("setCustomer", {
        ...state.customerEdit,
        locations: updatedLocations,
      });
      dispatch("update", { locations: updatedLocations });
    },

    addContact({ commit, dispatch, state }, contact) {
      // Update the customerEdit's contacts with the new contact
      contact.cID = state.cID;
      const updatedContacts = [ ...state.customerEdit.contacts, contact ];

      // Commit the mutation to update the contacts in the customerEdit
      commit("setCustomer", {
        ...state.customerEdit,
        contacts: updatedContacts,
      });
      dispatch("update", { contacts: updatedContacts });
    },

    deleteContact({ commit, dispatch, state }, contact) {
      // Update the customerEdit's contacts by removing the deleted contact
      const updatedContacts = state.customerEdit.contacts.filter(
        (cont) => cont.id !== contact.id
      );

      // Commit the mutation to update the contacts in the customerEdit
      commit("setCustomer", {
        ...state.customerEdit,
        contacts: updatedContacts,
      });
      dispatch("update", { contacts: updatedContacts });
    },

    removeNote({ commit }, noteId) {
      try {
        // Delete the note from Firebase
        firebase.note.delete(noteId);
        // Commit the mutation to remove the note from the state
        commit("removeNote", noteId);
      } catch (err) {
        console.error("Customer Module", err);
      }
    },

    async update({ commit, state }, updatedData) {
      try {
        const customerDataToUpdate = {};

        Object.keys(updatedData).forEach((key) => {
          const value = updatedData[ key ];
          if (Array.isArray(value)) {
            customerDataToUpdate[ key ] = value.map((item) => {
              return item.toSaveable ? item.toSaveable() : item;
            });
          } else {
            customerDataToUpdate[ key ] = value;
          }
        });

        if (Object.keys(customerDataToUpdate).length > 0) {
          // Update the edited customer data in Firebase
          firebase.customer.update(state.cID, customerDataToUpdate);

          // Commit the mutation to update the customerEdit in the state
          commit("setCustomer", {
            ...state.customerEdit,
            ...customerDataToUpdate,
          });
        }
      } catch (err) {
        console.error("Customer Module", err);
      }
    },

    resetState({ commit }) {
      if (listener) listener();
      if (pListener) pListener();
      if (nListener) nListener();
      commit("resetState");
    },
  },

  getters: {
    getCustomerID: (state) => state.cID,
    getCustomer: (state) => state.customer,
    getCustomerTitle: (state) => state.customer?.title,
    getCustomerName: (state) => state.customer?.name,
    getCustomerNr: (state) => state.customer?.nr,
    getCustomerWeb: (state) => state.customer?.web,
    getCustomerDesc: (state) => state.customer?.desc,

    getCustomerEdit: (state) => state.customerEdit,
    getCustomerEditTitle: (state) => state.customerEdit?.title,
    getCustomerEditName: (state) => state.customerEdit?.name,
    getCustomerEditNr: (state) => state.customerEdit?.nr,
    getCustomerEditWeb: (state) => state.customerEdit?.web,
    getCustomerEditDesc: (state) => state.customerEdit?.desc,

    getLocations: (state) => state.customer?.locations ?? [],
    getContacts: (state) => state.customer?.contacts ?? [],
    getProjects: (state) => state.projects,
    getNotes: (state) => state.notes,
    getCRM: (state) => (state.crm ? state.crm : {}),
    isProjectsLoaded: (state) => state.loaded.projects,
    isNotesLoaded: (state) => state.loaded.notes,
  },
};
