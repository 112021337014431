import Project from "../../types/project/project";
import firebase from "../../db/firebase/MFirebase";
import { orderBy, where } from "firebase/firestore";
import MiEvent from "../../types/event/mievent";
import Doc from "../../types/doc/doc";
import Note from "../../types/note/note";
import { Todo } from "../../types/todo/Todos";
import { FetchClass } from "../helper";
import { groupBy } from "lodash";
import Goal from "../../types/goal/goal";
import MiForm, { formsConfig } from "../../types/form/form";
import Basedata, { BasedataSimple } from "../../types/basedata/basedata";
import Vue from "vue";

let openProjectListener;

const initialState = {
  openProjects: [],

  projectID: null,
  project: null,
  viewMode: 0,
  selectedIds: {},

  listeners: {},
  conflicts: {},

  flags: {
    dialogVisible: false,
    loading: false,
    freshLoad: false,

    filesLoaded: false,
    imagesLoaded: false,

    loaded: {},

    fetch: {
      events: false,
      images: false,
      files: false,
      docs: false,
      listen: [],
    },
  },

  counts: {
    todos: 0,
    events: 0,
  },

  forms: [],
  docs: [],
  files: [],
  goals: [],
  todos: [],
  events: [],
  images: [],
  notes: [],

  // Basedata
  basedatas: {},
  basedatasByGroup: {},
};

let projListener;

export default {
  namespaced: true,
  name: "project",

  state: {
    ...initialState,
  },

  mutations: {
    // View Mode
    SET_VIEW_MODE(state, mode) {
      state.viewMode = mode;
    },

    // Project
    SET_PROJECT_ID(state, projectID) {
      console.log("Setted projectID", projectID);
      state.projectID = projectID;
    },

    SET_PROJECT(state, project) {
      if (!(project instanceof Project)) project = new Project();
      state.project = project;
      console.log("Setted Project", project);
    },

    SET_OPEN_PROJECTS(state, projects) {
      state.openProjects = projects;
      console.log("Setted openProjects", projects);
    },

    // Array Data and Listener
    SET_ARRAY_DATA(state, { arrayName, data }) {
      console.log("setArrayData", arrayName, data);
      if (!state.project) throw "No Project set";
      state[ arrayName ] = data;
    },

    SET_LISTENER(state, { arrayName, listener }) {
      state.listeners[ arrayName ] = listener;
    },

    // Flags and Loaded States
    SET_FLAG(state, { key, value }) {
      console.log("Set Flag", key, value);
      state.flags[ key ] = value;
    },

    SET_LOADED(state, key) {
      console.log("Set Loaded", key);
      state.flags.loaded[ key ] = true;
    },

    // Counts
    SET_COUNT(state, { key, count }) {
      console.log("Set Count", key, count);
      if (Object.prototype.hasOwnProperty.call(state.counts, key)) {
        state.counts[ key ] = count;
      }
    },

    // Project Details
    SET_TYPE(state, type) {
      state.project.type = type;
    },

    SET_TITLE(state, title) {
      state.project.title = title;
    },

    SET_CUSTOMER(state, customer) {
      state.project.customer = customer;
    },

    SET_LOCATION(state, location) {
      state.project.location = location;
    },

    SET_USERS(state, users) {
      state.project.users = users;
    },

    SET_EVENTS(state, events) {
      state.project.events = events;
    },

    SET_GOALS(state, goals) {
      state.project.goals = goals;
    },

    SET_SERIALS(state, serials) {
      state.project.serials = serials;
    },

    SET_FROM(state, from) {
      state.project.from = from;
    },

    SET_TO(state, to) {
      state.project.to = to;
    },

    // Tags
    SET_TAGS(state, tags) {
      state.project.tags = tags;
    },

    ADD_TAG(state, tag) {
      state.project.tags.push(tag);
    },

    REMOVE_TAG(state, tag) {
      state.project.tags = state.project.tags.filter((t) => t !== tag);
    },

    // Todos
    SET_TODOS(state, todos) {
      state.todos = todos;
    },

    SET_TODO_GROUPS(state, todoGroups) {
      state.todoGroups = todoGroups;
    },

    // Notes
    SET_NOTES(state, notes) {
      console.log("Setting Notes", notes);
      state.notes = notes;
    },

    ADD_NOTE(state, note) {
      console.log("Adding Note", note);
      state.notes.push(note);
    },

    REMOVE_NOTE(state, noteId) {
      console.log("Remove Note", noteId);
      const index = state.notes.findIndex((note) => note.id === noteId);
      if (index !== -1) {
        state.notes.splice(index, 1);
      }
    },

    // Docs
    SET_DOCS(state, docs) {
      console.log("Set Docs", docs);
      state.docs = docs;
    },

    ADD_DOC(state, doc) {
      console.log("Adding Doc", doc);
      state.docs.push(doc);
    },

    REMOVE_DOC(state, docId) {
      console.log("Remove Doc", docId);
      const index = state.docs.findIndex((doc) => doc.id === docId);
      if (index !== -1) {
        state.docs.splice(index, 1);
      }
    },

    // Images
    SET_IMAGES(state, images) {
      console.log("Set Images", images);
      state.images = images;
    },

    ADD_IMAGE(state, image) {
      console.log("Adding Image", image);
      state.images.push(image);
    },

    REMOVE_IMAGE(state, path) {
      console.log("Remove Image", path);
      const index = state.images.findIndex((it) => it.path === path);
      if (index !== -1) {
        state.images.splice(index, 1);
      }
    },

    // Files
    SET_FILES(state, files) {
      console.log("Set Files", files);
      state.files = files;
    },

    ADD_FILE(state, file) {
      console.log("Adding File", file);
      state.files.push(file);
    },

    REMOVE_FILE(state, path) {
      console.log("Remove File", path);
      const index = state.files.findIndex((it) => it.path === path);
      if (index !== -1) {
        state.files.splice(index, 1);
      }
    },

    // Basedatas
    // sets Maps
    SET_BASEDATAS(state, basedatas) {
      const convertedBasedatas = {};
      const groupedBasedatas = {};

      // [] or {} -> Check and fill Maps
      Object.values(basedatas).forEach((basedata) => {
        const isFull = basedata instanceof Basedata;
        const isSimple = basedata instanceof BasedataSimple;

        if (isFull || !isSimple) {
          basedata = new BasedataSimple(basedata);
        }

        const isNewGroup = !groupedBasedatas[ basedata.group ];
        if (isNewGroup) {
          // init group
          Vue.set(groupedBasedatas, basedata.group, {
            count: 0,
            entries: {},
          });
        }

        const isNewBd = !convertedBasedatas[ basedata.id ];
        if (!isNewBd) {
          console.warn("Overriding Basedata in Project");
          const isOldGroup =
            convertedBasedatas[ basedata.id ].group == basedata.group;
          if (isOldGroup) {
            groupedBasedatas[ basedata.group ].count -= 1;
          }
        }

        convertedBasedatas[ basedata.id ] = basedata;
        groupedBasedatas[ basedata.group ].count += 1;
        groupedBasedatas[ basedata.group ].entries[ basedata.id ] = basedata;
      });

      state.basedatas = convertedBasedatas;
      state.basedatasByGroup = groupedBasedatas;
    },

    SET_BASEDATA(state, basedata) {
      if (basedata) {
        // Convert
        const isFull = basedata instanceof Basedata;
        const isSimple = basedata instanceof BasedataSimple;
        if (isFull || !isSimple) basedata = new BasedataSimple(basedata);

        // New
        const isNew = !state.basedatas[ basedata.id ];
        if (isNew) {
          const isNewGroup = !state.basedatasByGroup[ basedata.group ];
          if (isNewGroup) {
            Vue.set(state.basedatasByGroup, basedata.group, {
              count: 0,
              entries: {},
            });
          }
          state.basedatasByGroup[ basedata.group ].count += 1;
        }

        Vue.set(state.basedatas, basedata.id, basedata);

        Vue.set(
          state.basedatasByGroup[ basedata.group ].entries,
          basedata.id,
          basedata
        );
      }
    },

    UPDATE_BASEDATA(state, { id, updatedBasedata }) {
      if (state.basedatas[ id ]) {
        state.basedatas[ id ] = updatedBasedata;

        const group = state.basedatas[ id ].group;
        if (state.basedatasByGroup[ group ]) {
          state.basedatasByGroup[ group ].entries[ id ] = updatedBasedata;
        }
      }
    },

    REMOVE_BASEDATA(state, id) {
      const basedata = state.basedatas[ id ];
      if (basedata) {
        Vue.delete(state.basedatas, id);

        const group = basedata.group;
        if (state.basedatasByGroup[ group ]) {
          Vue.delete(state.basedatasByGroup[ group ].entries, id);
          if (state.basedatasByGroup[ basedata.group ])
            state.basedatasByGroup[ basedata.group ].count -= 1;
        }
      }
    },

    // Forms
    SET_FORMS(state, forms) {
      state.forms = forms.map((it) => new MiForm(it));
      console.log("Setted forms", forms);
    },

    ADD_FORM(state, form) {
      // Add the form to the forms array
      state.forms.push(new MiForm(form));

      // Create a simplified version for the project.forms
      const projectForm = new MiForm({
        title: form.title,
        entries: form.entries.map((entry) => ({
          title: entry.title || null,
          value: entry.value || null,
        })),
      });

      // !simplified! form for the project.forms array
      state.project.forms.push(projectForm);
    },

    UPDATE_FORM(state, { index, form }) {
      // Update the existing form
      state.forms[ index ] = form;

      // Map the updated forms to a new array containing only the title and value
      state.project.forms = state.forms.map((form) => ({
        title: form.title,
        entries: form.entries.map((entry) => ({
          title: entry.title || null,
          value: entry.value || null,
        })),
      }));
    },

    // Reset
    RESET_STATE(state) {
      Object.assign(state, initialState);
      console.log("ProjModule", "Resetted State", state);
    },
  },

  actions: {
    // Tabs?
    setViewMode({ commit }, mode) {
      commit("SET_VIEW_MODE", mode);
    },

    // Basics
    // setID -> listener
    // -> setProject when changed
    async setProjectID({ commit, dispatch }, projectID) {
      commit("SET_PROJECT_ID", projectID);
      if (projListener) projListener();
      projListener = firebase.listenToOne(
        "project",
        projectID,
        (proj) => {
          dispatch("setProject", proj);
          return proj;
        },
        () => {
          dispatch("setProject", undefined);
        }
      );
    },

    async setProject({ state, commit, dispatch }, project) {
      if (!project) {
        commit("RESET_STATE");
        return;
      }

      if (!state.projectID) {
        dispatch("setProjectID", project.id);
        return;
      }

      dispatch("setProjectBasedatas", project.basedatas);
      dispatch("setForms", project.forms);
      // finished
      commit("SET_PROJECT", project);
      dispatch("fetch");
      return project;
    },

    // Location
    setProjectLocation({ commit }, location) {
      commit("SET_LOCATION", location);
    },

    // Forms
    // Bad
    setForms({ commit }, forms) {
      const combinedForms = formsConfig.map((configSection) => {
        const newSection = { ...configSection };
        // Find the matching section in forms by comparing titles
        const matchingSection = forms.find(
          (formSection) => formSection.title === configSection.title
        );

        newSection.entries = configSection.entries.map((entry) => {
          if (entry.type == "Divider") return entry;

          const newEntry = { ...entry }; // Start with null value
          // Find the matching entry in matchingSection by comparing titles
          const matchingEntry = matchingSection?.entries?.find(
            (formEntry) => formEntry.title === entry.title
          );
          if (matchingEntry) {
            if (
              newEntry.type == "Checkbox" &&
              !Array.isArray(matchingEntry.value)
            )
              "";
            else newEntry.value = matchingEntry.value; // Set the value if a match is found
          }
          return newEntry;
        });

        return newSection;
      });

      commit("SET_FORMS", combinedForms);
    },

    saveForm({ commit, state }, form) {
      const existingFormIndex = state.forms.findIndex(
        (f) => f.title === form.title
      ); // Find the index of the existing form by title

      if (existingFormIndex === -1) {
        // If title is not found, it's a new form
        commit("ADD_FORM", form); // Commit the ADD_FORM mutation
      } else {
        // If title is found, it's an existing form
        commit("UPDATE_FORM", { form, index: existingFormIndex }); // Commit the UPDATE_FORM mutation
      }
    },

    // Tags
    setTags({ commit }, tags) {
      commit("SET_TAGS", tags);
    },

    addTag({ commit, state }, tag) {
      if (!state.project.tags.includes(tag)) {
        commit("ADD_TAG", tag);
      }
    },

    removeTag({ commit }, tag) {
      commit("REMOVE_TAG", tag);
    },

    // Basedata
    setProjectBasedatas({ commit }, basedatas) {
      commit("SET_BASEDATAS", basedatas);
    },

    addBasedataToProject({ state, commit }, basedata) {
      commit("SET_BASEDATA", basedata);

      const basedatas = Object.values(state.basedatas);
      console.log(basedatas);
      const basedatasSaveable = basedatas.map((it) => it.toSaveable());
      console.log("Updating project with", basedatas);
      firebase.project.update(state.projectID, {
        basedatas: basedatasSaveable,
      });
    },

    setBasedataInProject({ commit }, payload) {
      commit("SET_BASEDATA", payload);
    },

    removeBasedataFromProject({ state, commit }, index) {
      commit("REMOVE_BASEDATA", index);

      const basedatas = Object.values(state.basedatas);
      const basedatasSaveable = basedatas.map((it) => it.toSaveable());

      firebase.project.update(state.projectID, {
        basedatas: basedatasSaveable,
      });
    },

    // Fetch when Project Setted
    fetch({ dispatch, state }) {
      if (state.flags.fetch.events) dispatch("fetchEvents");
      if (state.flags.fetch.images) dispatch("fetchImages");
      if (state.flags.fetch.files) dispatch("fetchFiles");
      state.flags.fetch.listen.forEach((it) => {
        dispatch("listenToType", it);
      });
      state.flags.fetch.listen = [];
    },

    // Set Listeners
    async fetchDocs({ dispatch }) {
      const fetchObj = new FetchClass("docs", "docs", Doc);
      await dispatch("fetchItems", fetchObj);
    },

    async fetchEvents({ dispatch }) {
      const fetchObj = new FetchClass("events", "events", MiEvent, [
        orderBy("start", "asc"),
      ]);
      await dispatch("fetchItems", fetchObj);
    },

    async fetchGoals({ dispatch }) {
      const fetchObj = new FetchClass("todoGroups", "todoGroups", Goal);
      await dispatch("fetchItems", fetchObj);
    },

    async fetchTodos({ dispatch, state }) {
      state;
      Todo;
      groupBy;
      const fetchObj = new FetchClass("todos", "todos", Todo);
      dispatch("fetchItems", fetchObj);
    },

    async fetchNotes({ dispatch }) {
      const fetchObj = new FetchClass("notes", "notes", Note);
      dispatch("fetchItems", fetchObj);
    },

    // StorageFiles
    async fetchImages({ state, commit, dispatch }) {
      commit("SET_FLAG", { key: "imagesLoaded", value: false });
      if (!state.project) {
        console.log("Set future fetch Images");
        state.flags.fetch.images = true;
        return;
      }
      await dispatch("fetchItemsFromStorage", "images");
      commit("SET_FLAG", { key: "imagesLoaded", value: true });
      state.flags.fetch.images = false;
    },

    async fetchFiles({ state, commit, dispatch }) {
      if (!state.project) {
        console.log("Set future fetch Files");
        state.flags.fetch.files = true;
        return;
      }
      commit("SET_FLAG", { key: "filesLoaded", value: false });
      await dispatch("fetchItemsFromStorage", "files");
      commit("SET_FLAG", { key: "filesLoaded", value: true });
      state.flags.fetch.files = false;
    },

    // Functions
    async updateCounts({ commit, state }, id) {
      const countKeys = Object.keys(state.counts);
      for (const key of countKeys) {
        const count = await firebase.getCountsFor(key, [
          where("pID", "==", id),
        ]);
        commit("SET_COUNT", { key, count });
      }
    },

    getOpenProjects({ commit }, user) {
      console.log("Get OPEN PROJECTS", "User is Admin", user?.isAdmin);

      if (openProjectListener) openProjectListener();
      if (!user) throw "No user to get projects";

      let extras = [ where("finished", "!=", true) ];
      if (!user.isAdmin) {
        extras.push(where("users", "array-contains", user.id));
      }

      openProjectListener = firebase.project.listenAll(
        (found) => {
          commit("SET_OPEN_PROJECTS", found);
        },
        [ ...extras ],
        (err) => console.log(err)
      );
    },

    // General Functions
    setArray({ commit }, { key, item }) {
      commit(`SET_${key.toUpperCase()}`, item);
    },

    async addItem({ commit, state }, { key, item }) {
      item.pID = state.project.id;
      await firebase.addItem(key, item);
      commit;
      // commit(`add${key.charAt(0).toUpperCase()}${key.slice(1)}`, item);
    },

    removeItem({ commit }, { key, item }) {
      console.log("REMOVE", item);
      let id = typeof item === "object" ? item.id : item;
      commit(`REMOVE_${key.toUpperCase()}`, id);
      firebase.deleteItem(key, id);
    },

    resetState({ commit, state }) {
      console.log("Reset State", state.listeners);
      Object.values(state.listeners).forEach((it) => it());
      if (openProjectListener) openProjectListener();
      commit("RESET_STATE");
    },

    // Helpers
    async fetchItems({ dispatch, state }, fetchObj) {
      if (!state.project) {
        console.log("Set future listenTo", fetchObj);
        state.flags.fetch.listen.push(fetchObj);
        return;
      }
      dispatch("listenToType", fetchObj);
    },

    async fetchItemsFromStorage({ commit, state }, key) {
      if (!state.project) {
        throw new Error("No Project to fetch items from");
      }
      if (!key) {
        throw new Error("Key missing for fetching Items");
      }

      console.log(`Fetching ${key} from storage`);

      const upperCaseKey = key.toUpperCase().slice(0, -1);

      if (!state.project.storage) {
        state.project.storage = {};
      }
      if (!state.project.storage[ key ]) {
        state.project.storage[ key ] = [];
      }
      if (state.project.storage[ key ].length === 0) {
        commit(`SET_${upperCaseKey}S`, []);
      }

      const newPaths = state.project.storage[ key ];
      const existingPaths = state[ key ].map((item) => item.path);

      const pathsToAdd = newPaths.filter(
        (path) => !existingPaths.includes(path)
      );
      const pathsToRemove = existingPaths.filter(
        (path) => !newPaths.includes(path)
      );

      try {
        // Remove items that are no longer in the project
        pathsToRemove.forEach((path) => {
          commit(`REMOVE_${upperCaseKey}`, path);
        });

        // Fetch and add new items
        for (let index = 0; index < pathsToAdd.length; index++) {
          let itemPath = pathsToAdd[ index ];
          itemPath = await firebase.storage.getURL(itemPath);
          commit(`ADD_${upperCaseKey}`, {
            path: itemPath,
            name: removeExtension(itemPath.split("/").pop()),
            type: itemPath.split(".").pop(),
          });
        }

        commit("SET_LOADED", key);
      } catch (error) {
        console.log(error);
      }
    },

    listenToType(
      { commit, state },
      { type, arrayName, extras, converter, onFound }
    ) {
      if (state.listeners[ arrayName ]) state.listeners[ arrayName ]();

      console.log("Setting listener for ", type, extras);
      let listener = firebase.listenTo(
        type,
        (data) => {
          if (onFound) onFound(data);
          commit("SET_ARRAY_DATA", { arrayName, data });
          commit("SET_LOADED", type);
        },
        [ where("pID", "==", state.project.id), ...extras ],
        (err) => console.log(err),
        converter
      );

      // Store the listener reference in the state
      commit("SET_LISTENER", { arrayName, listener });
    },
  },

  getters: {
    getProject: (state) => state.project,
    getViewMode: (state) => state.viewMode,
    getType: (state) => state.project.type,
    getTitle: (state) => state.project.title,
    getTags: (state) => state.project.tags,
    getCustomer: (state) => state.project.customer,
    getContact: (state) => state.project.contact,
    getLocation: (state) => state.project.location,
    getTodos: (state) => state.todos,
    getTodosForGoal: (state) => (goalID) =>
      state.todos.filter((it) => it.gID == goalID),
    getUsers: (state) => state.project.users,
    getEvents: (state) => state.events,
    getNotes: (state) => state.notes,
    getGoals: (state) => state.project.goals,
    getForms: (state) => state.forms,

    getBasedatasInProject: (state) => Object.values(state.basedatas),

    getBasedataGroupMap: (state) => state.basedatasByGroup,
    getBasedatasByGroup: (state, getters) => (groupKey) => {
      return Object.values(getters.getBasedataGroupMap[ groupKey ].entries);
    },

    getSerials: (state) => state.project.serials,
    getDocs: (state) => state.docs,
    getDocsAN: (state) => state.docs.filter((it) => it.docType == "AN"),
    getDocsAB: (state) => state.docs.filter((it) => it.docType == "AB"),
    getDocsLI: (state) => state.docs.filter((it) => it.docType == "LI"),
    getDocsRE: (state) => state.docs.filter((it) => it.docType == "RE"),
    getImages: (state) => state.images,
    getFiles: (state) => state.files,
    getFrom: (state) => state.project.from,
    getTo: (state) => state.project.to,
    getCreatedTime: (state) => state.project?.created?.time,
    getCreatedTitle: (state) => state.project?.created?.user?.title,
    getUpdatedTime: (state) => state.project?.updated?.time,
    getUpdatedTitle: (state) => state.project?.updated?.user?.title,

    //   Counts
    getTodoCount: (state) => state.counts.todos,
    getEventCount: (state) => state.counts.events,
    getDocCount: (state) => state.counts.docs,

    //   Filtered
    nextEvent: (state, getters) => {
      const current = new Date().getTime();
      const isRunning = (event) =>
        event.start <= current && event.end >= current;
      const hasStarted = (event) => event.start < current;
      return getters.getEvents.find(
        (event) => isRunning(event) || !hasStarted(event)
      );
    },

    //   Flags
    isFreshLoad: (state) => state.flags.freshLoad,
    isLoading: (state) => state.flags.loading,
    imagesLoaded: (state) => state.flags.imagesLoaded,
    filesLoaded: (state) => state.flags.filesLoaded,
    openProjects: (state) => state.openProjects,
    getLoaded: (state) => (key) => state.flags.loaded[ key ],
  },
};

function removeExtension(filename) {
  return filename.substring(0, filename.lastIndexOf(".")) || filename;
}
