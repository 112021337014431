import { cloneDeep } from "lodash";

import Struct from "./datas/note.json";
import { Type } from "../Types";
import firebase from "../../db/firebase/MFirebase";

export default class Note {
  constructor(note) {
    //console.log("Creating Note...", note);
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (note) Object.assign(this, note);
    this.type = new Type("note");
    // console.log("Created Note", this);
  }


  save() {
    firebase.note.set(this.toSaveable())
  }

  toSaveable() {
    const itemToSave = { ...cloneDeep(this) };
    delete itemToSave.type;
    return itemToSave;
  }
}
