var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticStyle:{"position":"relative"},style:({
    'background-color': _vm.mBGColor + ' !important',
  }),attrs:{"height":_vm.getHeight,"color":_vm.color,"href":_vm.href,"target":_vm.target,"rounded":"","icon":false}},'v-btn',_vm.$props,false),_vm.$listeners),[_c('div',{class:(_vm.left ? 'left ' : 'centered') +
      ' ' +
      (_vm.vertical ? 'vertical ' : 'horizontal gapped half '),staticStyle:{"width":"100%","max-height":"100%"},style:({
      color: _vm.textColor + ' !important',
    })},[_vm._t("icon",function(){return [(_vm.icon && !_vm.iconEnd)?_c('v-icon',{staticClass:"icon",staticStyle:{"margin":"auto 0"},attrs:{"color":_vm.iconColor,"large":_vm.large,"small":_vm.small,"x-small":_vm.xSmall,"outlined":""}},[_vm._v(" "+_vm._s(_vm.icon || "")+" ")]):_vm._e()]}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.badge),expression:"badge"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.badge)+" ")]),_vm._t("text",function(){return [(_vm.title || _vm.subtitle)?_c('div',{staticClass:"vertical centered-v",staticStyle:{"margin":"auto 0","text-align":"left"},style:({
          color: _vm.textColor + '!important',
        })},[_c('span',[_vm._v(" "+_vm._s(_vm.title || "")+" ")]),(_vm.subtitle)?_c('span',{staticStyle:{"font-size":"0.6em"}},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()]):_vm._e()]}),_vm._t("iconEnd",function(){return [(_vm.icon && _vm.iconEnd)?_c('v-icon',{staticClass:"icon",staticStyle:{"margin":"auto 0"},attrs:{"color":_vm.iconColor,"large":_vm.large,"small":_vm.small,"x-small":_vm.xSmall,"outlined":""}},[_vm._v(" "+_vm._s(_vm.icon || "")+" ")]):_vm._e()]}),(_vm.clearable)?_vm._t("clear",function(){return [_c('v-icon',{style:(_vm.title ? { 'margin-left': '0.5rem' } : {}),attrs:{"x-large":_vm.xLarge,"large":_vm.large,"medium":_vm.medium,"small":_vm.small,"x-small":_vm.xSmall,"dark":_vm.dark}},[_vm._v(" mdi-close ")])]}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }