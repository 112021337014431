import miIcons from "../plugins/icons";
import breads from "./breads";

// Setup
const LoginPage = () => import("../views/pages/_setup/LoginPage.vue");
const SettingsPage = () => import("../views/pages/SettingsPage.vue");

// Basics
const ChatPage = () => import("../views/pages/ChatPage.vue");
const SearchPage = () => import("../views/pages/SearchPage.vue");
const SearchActions = () => import("../views/app/actions/SearchActions.vue");

// Company
const CompanyPage = () => import("../types/company/CompanyPage.vue");
const CompanyActions = () =>
  import("../types/company/components/app/CompanyActions.vue");

// Project
const ProjectPage = () => import("../types/project/ProjectPage.vue");
const ProjectActions = () =>
  import("../types/project/components/app/ProjectActions.vue");
// const ProjectLeftActions = () =>
//   import("../types/project/components/app/ProjectLeftActions.vue");

// Types
const CustomerPage = () => import("../types/customer/CustomerPage.vue");
const BasedataPage = () => import("../types/basedata/BasedataPage.vue");
const BasedataActions = () =>
  import("../types/basedata/components/app/BasedataActions.vue");
const UserPage = () => import("../types/user/UserPage.vue");
const SupplierPage = () => import("../types/supplier/SupplierPage.vue");
const DocPage = () => import("../types/doc/DocPage.vue");
const DocActions = () => import("../types/doc/components/app/DocActions.vue");
const EventsPage = () => import("../types/event/EventsPage.vue");
const EventsProjPage = () => import("../types/event/EventsProjectPage.vue");

// Proj
const ProjectTodoPage = () => import("../types/project/ProjectTodoPage.vue");
// const DocItemsPage = () => import("../../_deletables/DocPlanPage.vue");

// Subs
const TestPage = () => import("../views/pages/TestPage.vue");
const ProjectMaterialInfoPage = () =>
  import("../types/project/components/ProjBasedataPage.vue");

export default [
  {
    path: "/test",
    name: "Test",
    component: TestPage,
    meta: {
      hideAppBarActions: false,
      hideFrame: true,
      hideSide: true,
      disableSideBtn: true,
      isSetup: true,
    },
  },
  {
    path: "/chat",
    name: "Chat",
    components: {
      default: ChatPage,
    },
    meta: {},
  },

  {
    path: "/settings",
    name: "SettingsPage",
    component: SettingsPage,
    meta: {
      breads: [ breads.dash, breads.settings ],
      isSetup: false,
    },
  },

  // Create
  {
    path: "/login",
    name: "Login",
    components: {
      setup: LoginPage,
    },
    meta: {
      hideFrame: true,
      hideSide: true,
      disableSideBtn: true,
      isSetup: true,
    },
  },
  {
    path: "/c/:compId",
    name: "Company",
    components: {
      default: CompanyPage,
      actions: CompanyActions,
    },
    meta: {
      breads: [ breads.dash ],
    },

    children: [],
  },

  // Start
  // {
  //   path: "/",
  //   name: "Dashboard",
  //   components: {
  //     setup: LoginPage,
  //   },
  //   meta: {
  //     hideFrame: true,
  //     hideSide: true,
  //     disableSideBtn: true,
  //     isSetup: true,
  //   },
  // },

  {
    path: "/search/",
    name: "SearchPage",
    components: {
      actions: SearchActions,
      default: SearchPage,
    },
    meta: {
      breads: [ breads.dash, breads.create(miIcons.search, "Suche", true) ],
    },
  },

  // InfoPages
  {
    path: "/c/:compId/user/:id",
    name: "User",
    alias: [ "/user" ],
    component: UserPage,
    meta: {
      breads(currentPathName) {
        return [
          breads.dash,
          breads.create(miIcons.user, currentPathName, true),
        ];
      },
    },
  },
  {
    path: "/c/:compId/customer/:id",
    name: "Customer",
    // alias: ["/customer/:id"],
    components: {
      default: CustomerPage,
    },
    meta: {
      breads: [ breads.dash, breads.customer ],
    },
  },
  {
    path: "/c/:compId/article/:id",
    name: "Basedata",
    alias: [ "/c/:compId/basedata/:id", "/c/:compId/material/:id" ],
    components: {
      actions: BasedataActions,
      default: BasedataPage,
    },
    meta: {
      breads(currentPathName) {
        return [
          breads.dash,
          breads.create(miIcons.basedata, currentPathName, true),
        ];
      },
    },
  },
  {
    path: "/c/:compId/supplier/:id",
    name: "Supplier",
    alias: [ "/supplier" ],
    components: {
      default: SupplierPage,
    },
    meta: {
      breads(currentPathName) {
        return [
          breads.dash,
          breads.create(miIcons.supplier, currentPathName, true),
        ];
      },
    },
  },
  {
    path: "/c/:compId/events",
    name: "Events",
    components: {
      default: EventsPage,
    },
    meta: {
      hideSide: true,
      breads() {
        return [ breads.dash, breads.create(miIcons.events, "Kalender") ];
      },
    },
  },

  // Project
  {
    path: "/c/:compId/project/:id/",
    name: "ProjectPage",
    alias: [ "/project/:pID" ],
    components: {
      default: ProjectPage,
      actions: ProjectActions,
      // innerActions: ProjectLeftActions,
    },
    meta: {
      // hideTop: true,
      breads: [ breads.dash, breads.project ],
    },
  },

  {
    path: "/c/:compId/project/:pID/events",
    name: "ProjectEvents",
    component: EventsProjPage,
    meta: {
      hideSide: true,
      breads() {
        return [
          breads.dash,
          breads.create(
            miIcons.project,
            "#" + this.$route.params.pID,
            false,
            "/project/" + this.$route.params.pID
          ),
          breads.create(miIcons.events, "Kalender"),
        ];
      },
    },
  },
  {
    path: "/c/:compId/doc/:id",
    alias: [ "/c/:compId/doc/:docID", "/c/:compId/project/:pID/doc/:docID/" ],
    name: "DocPage",
    components: {
      default: DocPage,
      actions: DocActions,
    },
    meta: {
      hideAppBarActions: false,
      hideSide: true,
      disableSideBtn: true,
      breads(name) {
        return [
          breads.dash,
          breads.project,
          {
            ...breads.docs,
            text: name,
          },
          // breads.print,
        ];
      },
    },
  },

  {
    path: "/c/:compId/project/:pID/material",
    name: "ProjMaterialPage",
    component: ProjectMaterialInfoPage,
    meta: {
      hideSide: true,
      breads() {
        let projID = this.$route.params.pID;
        return [
          breads.dash,
          breads.project,
          breads.create(`#${projID}`, false, "/project/" + projID),
          breads.create("Material", true),
        ];
      },
    },
  },

  {
    path: "/c/:compId/project/:pID/todos",
    name: "ProjectTodoInfoPage",
    component: ProjectTodoPage,
    meta: {
      hideSide: true,
      disableSideBtn: true,
      hideAppBarActions: true,
      breads() {
        let projID = this.$route.params.pID;
        return [
          breads.dash,
          breads.project,
          breads.create(`#${projID}`, false, "/project/" + projID),
          breads.todos,
        ];
      },
    },
  },
];
