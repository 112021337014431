import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import Basedata from "../../types/basedata/basedata";
import extras from "../data/bdextras.json";
import BDGroupsDoc from "../../db/firebase/singles/BDGroupsDoc";
import BasedataGroup from "../../types/basedata/basedataGroup";

const initState = {
  mode: 0,
  basedataID: null,
  basedata: null,
  basedataEdit: new Basedata(),

  ...extras,
};

let listener;

export default {
  namespaced: true,
  name: "basedata",

  state: {
    ...cloneDeep(initState),
    basedataGroupDoc: BDGroupsDoc,
    basedataGroups: [],
    groupItemsIDs: [],
    groupItemPreviews: {},
  },

  mutations: {
    setMode(state, mode) {
      state.mode = mode;
    },
    setBasedataID(state, id) {
      state.basedataID = id;
    },

    setBasedata(state, basedata) {
      state.basedata = basedata;
    },

    setBasedataEdit(state, basedata) {
      state.basedataEdit = new Basedata(basedata);
    },

    resetState(state) {
      Object.assign(state, initState);
    },

    addSupplierWithDiscount(state, { supplierId, discounts }) {
      const supplier = {
        supplierId,
        discounts,
      };
      state.basedata.suppliers.push(supplier);
    },

    addSupplier(state) {
      state.basedataEdit.supplier.push({
        title: "",
        discounts: [],
      });
    },

    removeSupplier(state, supplier) {
      state.basedataEdit.supplier = state.basedataEdit.supplier.filter(
        (item) => item !== supplier
      );
    },

    addDiscount(state, { supplier, discount }) {
      if (!supplier.discounts) supplier.discounts = [];
      supplier.discounts.push(discount);
    },

    saveDiscount(state, { supplier, index, newDiscount }) {
      if (index >= 0 && index < supplier.discounts.length) {
        supplier.discounts[ index ] = newDiscount;
      }
    },

    removeDiscount(state, { supplier, discount }) {
      supplier.discounts = supplier.discounts.filter(
        (item) => item !== discount
      );
    },

    // Groups
    SET_CURRENT_BASEDATA_GROUP(state, group) {
      if (!state.basedata) return console.error("No current Basedata");
      state.basedata.group = group;
    },

    SET_GROUPS_DOC(state, doc) {
      state.basedataGroupsDoc = doc;
      state.basedataGroups = doc.entries.map((it) => new BasedataGroup(it));
      console.log("Setting GroupDoc", doc, state.basedataGroups);
    },

    ADD_GROUP_ITEM(state, item) {
      state.groupItemPreviews[ item.id ] = item;
    },

    SET_GROUPS(state, basedataGroups) {
      state.basedataGroups = basedataGroups;
    },

    ADD_BASEDATA_GROUP(state, basedataGroup) {
      state.basedataGroups.push(basedataGroup);
    },

    REMOVE_BASEDATA_GROUP(state, basedataGroup) {
      let index = state.basedataGroups.indexOf(basedataGroup);
      if (index !== -1) {
        state.basedataGroups.splice(index, 1);
      }
    },

    ADD_BASEDATA_TO_GROUP(state, basedata) {
      const targetGroup = state.basedataGroups.find(
        (g) => g.title === basedata.group
      );
      if (targetGroup && !targetGroup.items.includes(basedata.id)) {
        targetGroup.items.push(basedata.id);
      }
    },

    REMOVE_BASEDATA_FROM_GROUP(state, basedata) {
      const targetGroup = state.basedataGroups.find(
        (g) => g.title === basedata.group
      );
      if (targetGroup) {
        const index = targetGroup.items.indexOf(basedata.id);
        if (index !== -1) {
          targetGroup.items.splice(index, 1);
        }
      }
    },
  },

  actions: {
    // Fetches
    async fetchBasedataGroups({ dispatch, commit }) {
      console.log("Listen to BD Groups");
      firebase.basedataGroup.listen(
        (bdGroupDoc) => {
          commit("SET_GROUPS_DOC", bdGroupDoc);
          dispatch("setGroupsPreview", bdGroupDoc);
        },
        (err) => {
          console.error(err);
        },
        () => {
          firebase.basedataGroup.set(BDGroupsDoc);
        }
      );
    },

    async setGroupsPreview({ commit }, groupDoc) {
      groupDoc.entries.forEach((it) => {
        const lastItems = it.items.slice(-5);
        lastItems.forEach(async (it) => {
          let item = await firebase.basedata.get(it);
          if (item) commit("ADD_GROUP_ITEM", item);
        });
      });
    },

    // Basics

    // Bad?
    setBasedataMode({ commit }, mode) {
      commit("setMode", mode);
    },
    //

    async setBasedataID({ commit, dispatch }, id) {
      commit("setBasedataID", id);
      if (listener) listener();

      listener = firebase.listenToOne(
        "basedata",
        id,
        (found) => {
          dispatch("setBasedata", found);
        },
        (err) => {
          console.error("Basedata Module", err);
        }
      );
    },

    async setBasedata({ state, commit }, basedata) {
      if (!basedata) {
        commit("resetState");
        return;
      }

      if (!(basedata instanceof Basedata)) {
        basedata = new Basedata(basedata);
      }

      if (state.basedataID != basedata.id) commit("setBasedataID", basedata.id);

      commit("setBasedata", basedata);
      commit("setBasedataEdit", basedata);
      // dispatch("fetchEntries");
    },

    saveItem({ commit, state }) {
      try {
        // Save the edited basedata data to Firebase
        firebase.basedata.set(
          state.basedataEdit.toSaveable
            ? state.basedataEdit.toSaveable()
            : state.basedataEdit
        );

        // Commit the mutation to update the basedata in the state
        commit("setBasedata", state.basedataEdit);
      } catch (err) {
        console.error("Basedata Module", err);
      }
    },

    updateEdit({ commit, state }, { key, value }) {
      if (key in state.basedataEdit) {
        commit("setBasedataEdit", {
          ...state.basedataEdit,
          [ key ]: value,
        });
      }
    },

    // Checks also if there are Arrays with custom Objects
    async update({ commit, state }, updatedData) {
      try {
        const basedataDataToUpdate = {};

        Object.keys(updatedData).forEach((key) => {
          const value = updatedData[ key ];
          if (Array.isArray(value)) {
            basedataDataToUpdate[ key ] = value.map((item) => {
              return item.toSaveable ? item.toSaveable() : item;
            });
          } else {
            basedataDataToUpdate[ key ] = value;
          }
        });

        if (Object.keys(basedataDataToUpdate).length > 0) {
          firebase.basedata.update(state.basedataID, basedataDataToUpdate);
          commit("setBasedata", {
            ...state.basedataEdit,
            ...basedataDataToUpdate,
          });
        }
      } catch (err) {
        console.error("Basedata Module", err);
      }
    },

    resetState({ commit }) {
      if (listener) listener();
      commit("resetState");
    },

    // Subs
    async updateBasedataTitle({ commit, state, dispatch }, title) {
      commit("setBasedataEdit", { ...state.basedataEdit, title });
      dispatch("update", { title: title });
    },

    async updateBasedataSku({ commit, state, dispatch }, sku) {
      commit("setBasedataEdit", { ...state.basedataEdit, sku });
      dispatch("update", { sku: sku });
    },

    async updateBasedataDescription({ commit, state, dispatch }, desc) {
      commit("setBasedataEdit", { ...state.basedataEdit, desc });
      dispatch("update", { desc: desc });
    },

    // Suppliers
    async addSupplierWithDiscount(
      { commit, state, dispatch },
      { supplierId, discounts }
    ) {
      try {
        if (!supplierId || !discounts) return;
        // Validate the supplierId and discounts before adding
        // For example, check if the supplierId exists and the discounts are valid

        // Perform any necessary validation and data sanitization here

        // Add the supplier with discounts to basedata
        commit("addSupplierWithDiscount", { supplierId, discounts });

        // Save the updated basedata to Firebase or any other database
        // You may have a saveItem action to handle this
        await dispatch("saveItem");

        // Optionally, you can also return the updated basedata object to the caller
        return state.basedata;
      } catch (error) {
        // Handle any errors during the process
        console.error("Error adding supplier with discounts: ", error);
        throw error;
      }
    },

    removeSupplier({ commit, state }, supplier) {
      if (state.basedataEdit.supplier.length > 1) {
        commit("removeSupplier", supplier);
      } else {
        console.error(
          "Basedata Module",
          "At least one supplier must be present."
        );
      }
    },

    // Discounts
    addDiscount({ commit, state }, { supplier, isVK }) {
      if (!supplier) {
        console.error("Basedata Module", "Supplier not found.");
        return;
      }

      const newDiscount = isVK
        ? cloneDeep(state.addDiscVK)
        : cloneDeep(state.addDisc);
      commit("addDiscount", { supplier, discount: newDiscount });
    },

    saveDiscount({ commit }, { supplier, index, newDiscount }) {
      commit("saveDiscount", { supplier, index, newDiscount });
    },

    removeDiscount({ commit }, { supplier, discount }) {
      commit("removeDiscount", { supplier, discount });
    },

    uploadDocToBasedata({ commit }, upload) {
      commit;
      upload;
    },

    deleteBasedata({ commit }, upload) {
      commit;
      upload;
    },

    // Groups
    async setCurrentBasedataGroup({ state, commit, dispatch }, group) {
      if (state.basedata.group)
        dispatch("removeBasedataFromGroup", state.basedata);

      commit("SET_CURRENT_BASEDATA_GROUP", group.title);

      dispatch("addBasedataToGroup", state.basedata);

      await dispatch("update", {
        group: group.title,
      });

      // await firebase.basedataGroup.update({ entries: state.basedataGroups });
    },

    addBasedataGroup({ commit, state }, basedataGroup) {
      commit("ADD_BASEDATA_GROUP", basedataGroup);
      firebase.basedataGroup.update({
        entries: state.basedataGroups,
      });
    },

    removeBasedataGroup({ commit, state }, basedataGroup) {
      commit("REMOVE_BASEDATA_GROUP", basedataGroup);
      firebase.basedataGroup.update({
        entries: state.basedataGroups,
      });
    },

    addBasedataToGroup({ state, commit }, basedata) {
      commit("ADD_BASEDATA_TO_GROUP", basedata);
      firebase.basedataGroup.update({
        entries: state.basedataGroups,
      });
    },

    removeBasedataFromGroup({ state, commit }, basedata) {
      commit("REMOVE_BASEDATA_FROM_GROUP", basedata);
      firebase.basedataGroup.update({
        entries: state.basedataGroups,
      });
    },
  },

  getters: {
    getBasedataMode: (state) => state.mode,
    getBasedataModes: (_, getters) => [
      { title: "Dashboard", icon: "mdi-information-variant-circle" },
      {
        title: "Lieferanten",
        icon: "$supplier",
        badge: getters.getBasedataSupplierCount,
      },
      {
        title: "Cloud",
        icon: "mdi-cloud-upload",
        badge: getters.getBasedataDocsCount,
      },
    ],

    // Groups
    getBasedataGroups: (state) => {
      console.log("GEEEET", state.basedataGroups);
      return state.basedataGroups;
    },
    getCurrentBasedataGroup: (state, getters) => {
      return getters[ "getBasedataGroups" ].find(
        (group) => group.title === state.basedata.group
      );
    },
    getBasedataPreviewByID: (state) => (id) => state.groupItemPreviews[ id ],
    getBasedataGroupPreviews: (state) => state.groupItemPreviews,

    getBasedataID: (state) => state.basedataID,
    getBasedata: (state) => state.basedata,
    getBasedataEdit: (state) => state.basedataEdit,

    getBasedataSuppliers: (state) => state.basedata?.supplier || [],
    getBasedataSupplierCount: (state) => state.basedata?.supplier?.length || 0,

    // Getters for sub-objects from bdextras.json
    getColors: (state) => state.colors,
    getConditions: (state) => state.conditions,
    getCountConditions: (state) => state.conditions.count,
    getPriceConditions: (state) => state.conditions.price,

    getUnits: (state) => state.units,

    // Get the dimensions units
    getDimensionUnits: (state) => state.units.dimensions,

    // Get the weight units
    getWeightUnits: (state) => state.units.weight,

    getTrackingModes: (state) => state.trackingModes,
    getVatCodes: (state) => state.vatCodes,
    getProductStates: (state) => state.productStates,

    // Get the total number of discounts for all suppliers in basedata
    getTotalDiscountCount: (state) => {
      let discounts = 0;
      state.basedata.suppliers?.forEach(
        (supp) => (discounts += supp.discounts.length)
      );
      return discounts;
    },

    // Getter to get the array of documents
    getBasedataDocs: (state) => state.basedata.docs || [],

    // Getter to get the count of documents
    getBasedataDocsCount: (state) =>
      state.basedata?.docs ? state.basedata.docs.length : 0,

    // Calculate the profit (gewinn) for basedata
    getProfit: (state) => {
      return (
        (state.basedata.vk ? state.basedata.vk : 0) -
        (state.basedata.ek ? state.basedata.ek : 0)
      );
    },

    // Calculate the profit percentage (gewinnInProzent) for basedata
    getProfitPercentage: (state, getters) => {
      if (!state.basedata.ek && !state.basedata.vk) return "N/A";
      if (!state.basedata.ek) return "100%";
      if (!state.basedata.vk || state.basedata.vk == 0) return "-100%";
      return Math.round((getters.getProfit / state.basedata.vk) * 100) + "%";
    },
  },
};
