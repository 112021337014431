// Debug things in Development
console.log("START DEV BUMMS");
export const isDev = () => process.env.NODE_ENV === "development";
if (isDev()) console.log("Started in Dev-Mode");
const originalConsoleLog = console.log;
const originalConsoleError = console.error;
const originalConsoleWarn = console.warn;

function isIterable(obj) {
  return obj != null && typeof obj[Symbol.iterator] === "function";
}

console.log = (...entries) => {
  if (isDev() && isIterable(entries)) originalConsoleLog(...entries);
};
console.error = (...entries) => {
  if (isDev() && isIterable(entries)) originalConsoleError(...entries);
};
console.warn = (...entries) => {
  if (isDev() && isIterable(entries)) originalConsoleWarn(...entries);
};
console.todo = (...entries) => {
  if (isDev() && isIterable(entries))
    originalConsoleLog("**TODO**", ...entries);
};

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router";
import firebase from "./db/firebase/MFirebase";
import Types from "./_tosort/structures/types.json";
import icons from "./plugins/icons";
import { Type } from "./types/Types";

import lang from "./lang/lang";

const i18n = lang;

vuetify.lang = {
  t: (key, ...params) => i18n.t(key, params),
};

const addableTypes = {};

Types.addable.forEach((it) => {
  addableTypes[it.key] = new Type(it.key);
});

Vue.config.productionTip = false;
Vue.prototype.$firebase = firebase;
Vue.prototype.$types = addableTypes;
Vue.prototype.$icons = icons;

new Vue({
  i18n,
  store,
  vuetify,
  router,
  firebase,
  render: (h) => h(App),
}).$mount("#app");

String.prototype.hashCode = function () {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

function moneyString(hideSymbol) {
  let result = Number(this).toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
  if (hideSymbol) {
    result = result.replace("€", "").trim();
  }
  return result;
}

Number.prototype.toMoneyString = moneyString;
String.prototype.toMoneyString = moneyString;
