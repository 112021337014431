<template>
  <v-app class="app">
    <v-btn
      class="fixed top left"
      color="white"
      icon
      style="z-index: 10"
      @click="toggleHideLeft()"
    >
      <v-img max-width="52" src="@/assets/micom_round.png" />
      <!-- disableSideSwitch -->
    </v-btn>

    <v-btn
      class="fixed top right"
      color="white"
      icon
      style="z-index: 10"
      @click="toggleHideRight()"
    >
      <v-icon x-large size="52">
        mdi-dots-horizontal-circle{{ getHideRight ? "-outline" : "" }}
      </v-icon>
    </v-btn>

    <top-bar class="hide-print" />
    <side-bar class="hide-print" />
    <right-app-bar class="hide-print" />

    <v-main>
      <div class="main vertical grow" v-if="!isDashboard">
        <router-view class="router-actions" name="actions" />

        <div class="scroll-white">
          <router-view />
        </div>
        <router-view class="router-bottom" name="bottom" />
      </div>
      <div class="main horizontal grow" v-else>
        <!-- <router-view /> -->
        <div
          class="grid grow"
          v-if="widgets.length"
          style="width: 66%"
          :class="widgets.length == 1 ? 'single' : ''"
        >
          <mi-widget
            :widget="widget"
            v-for="widget in widgets"
            :key="widget.id"
            class="grid-item"
          />
        </div>
      </div>
    </v-main>

    <doc-print-sheet
      v-if="$store.state.printDoc"
      :doc="$store.state.printDoc"
      class="only-print"
    />

    <quick-actions v-if="false" class="hide-print" />

    <!-- Dialogs -->
    <div class="dialogs hide-print">
      <project-dialog
        v-if="getDialog('project').show"
        :show="getDialog('project').show"
        :project="getDialog('project').data"
        @showDial="setShow('project', $event)"
      />

      <add-dialog v-if="getDialog('add').show" />

      <customer-dialog
        v-if="getDialog('customer').show"
        :show="getDialog('customer').show"
        :customer="getDialog('customer').data"
        @showDial="setShow('customer', $event)"
      />

      <supplier-dialog
        v-if="getDialog('supplier').show"
        :show="getDialog('supplier').show"
        :supplier="getDialog('supplier').data"
        @showDial="setShow('supplier', $event)"
      />

      <basedata-dialog
        v-if="getDialog('basedata').show"
        :show="getDialog('basedata').show"
        :basedata="getDialog('basedata').data"
        @showDial="setShow('basedata', $event)"
      />

      <user-dialog
        v-if="getDialog('user').show"
        :show="getDialog('user').show"
        :user="getDialog('user').data"
        @showDial="setShow('user', $event)"
      />

      <request-dialog v-if="getDialogShow('request')" />

      <contact-dialog v-if="getDialog('contact').show" />

      <event-edit-dialog
        v-if="getDialog('event').show"
        :event="getDialog('event').data"
      />

      <add-todo-dialog v-if="getDialog('todo').show" />

      <logo-dialog v-if="getDialog('logo').show" />

      <company-dialog v-if="getDialog('company').show" />

      <note-dialog v-if="getDialogShow('note')" />

      <location-dialog v-if="getDialogShow('location')" />

      <form-dialog v-if="getDialogShow('form')" />

      <basedata-select-dialog v-if="getDialogShow('basedataSelect')" />

      <basedata-group-dialog v-if="getDialogShow('basedataGroup')" />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TopBar from "./bars/top/TopBar.vue";
import SideBar from "./bars/left/SideBar.vue";
import RightAppBar from "./bars/right/RightAppBar.vue";
import DocPrintSheet from "../../types/doc/components/DocPrintSheet.vue";
import LocationDialog from "../../types/location/dialogs/LocationDialog.vue";
import MiWidget from "../components/MiWidget.vue";
import FormDialog from "../../types/form/dialogs/FormDialog.vue";
// import { Types } from "../../types/Types";

const AddDialog = () => import("../dialogs/add/AddDialog.vue");
const CompanyDialog = () =>
  import("../../types/company/dialogs/CompanyDialog.vue");
const CustomerDialog = () =>
  import("../../types/customer/dialogs/CustomerDialog.vue");
const ProjectDialog = () =>
  import("../../types/project/dialogs/ProjectDialog.vue");
const SupplierDialog = () =>
  import("../../types/supplier/dialogs/SupplierDialog.vue");
const UserDialog = () => import("../../types/user/dialogs/UserDialog.vue");
const ContactDialog = () =>
  import("../../types/contact/dialogs/ContactDialog.vue");
const EventEditDialog = () =>
  import("../../types/event/dialogs/EventEditDialog.vue");
const LogoDialog = () => import("../dialogs/LogoDialog.vue");
const BasedataDialog = () =>
  import("../../types/basedata/dialogs/BasedataDialog.vue");
const AddTodoDialog = () =>
  import("../../types/todo/dialogs/AddTodoDialog.vue");
const RequestDialog = () => import("../dialogs/RequestDialog.vue");
const NoteDialog = () => import("../../types/note/dialogs/NoteDialog.vue");
const BasedataSelectDialog = () =>
  import("../../types/basedata/dialogs/BasedataSelectDialog.vue");
const BasedataGroupDialog = () =>
  import("../../types/basedata/dialogs/BasedataGroupDialog.vue");

export default {
  components: {
    DocPrintSheet,
    ProjectDialog,
    AddDialog,
    CustomerDialog,
    SupplierDialog,
    CompanyDialog,
    SideBar,
    TopBar,
    UserDialog,
    ContactDialog,
    EventEditDialog,
    LogoDialog,
    BasedataDialog,
    AddTodoDialog,
    RequestDialog,
    RightAppBar,
    NoteDialog,
    LocationDialog,
    MiWidget,
    FormDialog,
    BasedataSelectDialog,
    BasedataGroupDialog,
  },
  name: "MisolarMainPage",

  data() {
    return {
      showUsers: true,
      widgets: [
        // {
        //   name: Types.project.router.name,
        // },
        // {
        //   name: "Customer",
        // },
      ],
    };
  },

  computed: {
    ...mapGetters("context", ["users"]),
    ...mapGetters("dialog", ["getDialog", "getDialogShow"]),
    ...mapGetters("miuser", ["getUser", "img"]),
    ...mapGetters("company", ["getCompany"]),
    ...mapGetters("appbar", ["getHideSide", "getHideRight", "getHideLeft"]),

    isDashboard() {
      return this.widgets.length && this.$route.name == "Dashboard";
    },
  },

  mounted() {},

  methods: {
    ...mapActions("dialog", ["showDialog"]),
    ...mapActions("customer", ["setCustomerID"]),
    ...mapActions("project", ["setProjectID"]),
    ...mapActions("basedata", ["fetchBasedataGroups"]),
    ...mapActions("appbar", [
      "toggleHideSide",
      "toggleHideLeft",
      "toggleHideRight",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.app {
  background-color: var(--v-primary-darken1) !important;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(200px, auto);
  gap: 10px;

  &.single {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  width: 100%;
  height: 100%;

  .grid-item {
    // max-width: 100%;
    overflow-y: auto;
  }
}

.main {
  height: 100%;
  max-height: calc(100vh - 64px);

  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;

  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  background-color: rgba(245, 245, 245, 0.075);
  padding-bottom: 1rem;
}

.router-actions {
  padding: 0.5rem;
  flex-shrink: 0; // this prevents shrinking of action bar
}

.router-bottom {
  padding: 0.5rem;
  flex-shrink: 0; // this prevents shrinking of action bar
}

.scroll-white {
  height: 100%;
  padding: 0.5rem;
  border-radius: 2rem;
  flex-grow: 1; // this will allow scroll-white to take remaining space
  overflow-y: auto;

  background-color: rgba(245, 245, 245, 0.025);
  box-shadow: inset 0 0 16px rgba(20, 20, 20, 0.5);
}

// SideBar right
.users {
  border-radius: 2rem;
  padding: 1rem 8px;
}
</style>
