import Struct from "./datas/basedataGroup.json";

export default class BasedataGroup {
  constructor(bdGroup) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (bdGroup) {
      Object.assign(this, bdGroup);
      if (bdGroup instanceof BasedataGroup) return;
    }
    this.checkItemsForNull();
  }

  checkItemsForNull() {
    this.items = this.items.filter(
      (item) => item !== null && item !== undefined
    );
  }

  toSaveable() {
    const saveableItem = { ...this };
    return saveableItem;
  }
}
