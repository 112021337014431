<template>
  <v-btn
    v-show="show"
    v-bind="$props"
    v-on="$listeners"
    :height="getHeight"
    :color="color"
    :href="href"
    :target="target"
    rounded
    :icon="false"
    style="position: relative"
    :style="{
      'background-color': mBGColor + ' !important',
    }"
  >
    <div
      :class="
        (left ? 'left ' : 'centered') +
        ' ' +
        (vertical ? 'vertical ' : 'horizontal gapped half ')
      "
      style="width: 100%; max-height: 100%"
      :style="{
        color: textColor + ' !important',
      }"
    >
      <slot name="icon">
        <v-icon
          v-if="icon && !iconEnd"
          :color="iconColor"
          :large="large"
          :small="small"
          :x-small="xSmall"
          outlined
          class="icon"
          style="margin: auto 0"
        >
          {{ icon || "" }}
        </v-icon>
      </slot>

      <span class="badge" v-show="badge">
        {{ badge }}
      </span>

      <slot name="text">
        <div
          v-if="title || subtitle"
          class="vertical centered-v"
          style="margin: auto 0; text-align: left"
          :style="{
            color: textColor + '!important',
          }"
        >
          <span> {{ title || "" }} </span>
          <span v-if="subtitle" style="font-size: 0.6em">
            {{ subtitle }}
          </span>
        </div>
      </slot>
      <slot name="iconEnd">
        <v-icon
          v-if="icon && iconEnd"
          :color="iconColor"
          :large="large"
          :small="small"
          :x-small="xSmall"
          outlined
          class="icon"
          style="margin: auto 0"
        >
          {{ icon || "" }}
        </v-icon>
      </slot>
      <slot name="clear" v-if="clearable">
        <v-icon
          :style="title ? { 'margin-left': '0.5rem' } : {}"
          :x-large="xLarge"
          :large="large"
          :medium="medium"
          :small="small"
          :x-small="xSmall"
          :dark="dark"
        >
          mdi-close
        </v-icon>
      </slot>
    </div>
  </v-btn>
</template>

<script>
import { VBtn } from "vuetify/lib";

export default {
  name: "ConButton",

  mixins: [VBtn],

  props: {
    badge: String,
    disabled: Boolean,
    vertical: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    elevation: {
      type: Number,
      default: 0,
    },
    openDelay: {
      type: Number,
      default: 200,
    },

    bg: Boolean,
    iconEnd: Boolean,
    href: String,
    target: String,
    item: Object,
    icon: String,
    clearable: Boolean,
    left: Boolean,
    title: String,
    subtitle: String,
    height: String,
    iconColor: String,
    color: {
      type: String,
      default: "transparent",
    },
    textColor: String,
    backgroundColor: String,
    hoverIcon: String,
  },
  data() {
    return {};
  },
  computed: {
    mBGColor() {
      return this.backgroundColor || (this.bg ? "#fefefe33" : undefined);
    },
    getHeight() {
      if (this.height) return this.height;
      if (this.vertical) return "auto";
      if (this.xLarge) return 64;
      if (this.large) return 56;
      if (this.medium) return 48;
      if (this.small) return 40;
      if (this.xSmall) return 32;
      return 48;
    },
  },
};
</script>

<style lang="scss" scoped>
.vertical {
  // padding: 1rem 0.25rem;
}
</style>
