import { cloneDeep } from "lodash";

import { Type } from "../Types";
import Struct from "./data/miForm.json";

// TODO: outsource
export const formsConfig = [
  {
    title: "Objektdaten",
    icon: "mdi-home",
    entries: [
      {
        title: "Objektart",
        type: "String",
        value: null,
      },
      {
        title: "Nutzfläche",
        type: "String",
        value: null,
        unit: "m²", // or "m²" if you can display special characters
      },
      {
        title: "Ölverbrauch",
        type: "String",
        value: null,
        unit: "L", // Assuming you're measuring in Liters
      },
      {
        title: "Gasverbrauch",
        type: "Number",
        value: null,
        unit: "m³", // Assuming you're measuring in cubic meters
      },
      {
        title: "Gebäudeheizlast",
        type: "String",
        value: null,
        unit: "W", // Assuming you're measuring in Watts
      },
    ],
  },
  {
    title: "Leistungsbeschreibung",
    icon: "mdi-card-bulleted",
    entries: [
      {
        title: "Anzahl Personen",
        type: "Number", // Assuming this is a numeric entry for the number of people
        value: null,
      },

      {
        title: "Warmwasserbedarf",
        type: "Number", // Assuming this is a numeric entry for the energy requirement
        value: null,
        unit: "kWh/A", // Assuming "kWh/A" is a unit of measurement
      },
      {
        title: "Kühlleistung",
        type: "Number",
        value: null,
        unit: "kW",
      },
      {
        title: "Vorlauftemperatur",
        type: "Number",
        value: null,
        unit: "°C",
      },

      {
        type: "Divider",
      },

      {
        title: "Heizung",
        type: "Checkbox",
        multiple: true,
        entries: [
          "Naturkühlung",
          "Umkehrlüftung",
          "Solaranlage",
          "Schwimmbad",
          "Wasseraufbereitung",
        ],
        value: [],
      },
      {
        title: "Heizungsanlage",
        type: "Checkbox",
        entries: [
          "Fußbodenheizung ohne ERR",
          "Fußbodenheizung mit ERR",
          "Radiatoren",
          "RLT-Anlage",
          "Betonkernaltivierung",
          "Gebläsekonvektoren",
        ],
        value: null,
      },
      {
        title: "Kühlung",
        type: "Checkbox",
        entries: [
          "Fußbodenheizung",
          "Betonkernaltivierung",
          "Gebläsekonvektoren",
          "RLT-Anlage",
        ],
        value: [],
      },
      {
        title: "Trink-Warmwasserbereitung",
        type: "Checkbox",
        entries: [ "zentral", "dezentral" ],
        value: [],
      },

      {
        title: "Wärmequelle",
        type: "Checkbox",
        entries: [
          "Außenluft",
          "Grundwasser",
          "Erdsonde",
          "Fortluft",
          "Flächenkollektoren",
          "Gründungspfähle",
        ],
        value: [],
      },
    ],
  },
];

export default class MiForm {
  constructor(formular) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (formular) {
      Object.assign(this, formular);
      if (formular instanceof MiForm) return;
    }
    this.type = new Type("form");

    // console.log("Created new Form", formular);
  }

  toSaveable() {
    let item = { ...cloneDeep(this) };
    item.entries;
    delete item.type;
    return item;
  }
}
