import { miUser } from "../db/firebase/MFirebase";
import { generateText as generateTextFromGpt } from "./services/GptService";
import messageStruct from "./struct.json";
import vuex from "../store";

export function createMessage(role, content) {
  let newMessage = { ...messageStruct.message };
  newMessage.role = role;
  newMessage.content = content;
  if (role === "user") {
    newMessage.userId = miUser.id;
  }
  newMessage.timestamp = new Date().getTime();
  return newMessage;
}

function toApiFormat(message) {
  return {
    role: message.role,
    content: message.content,
  };
}

function fromApiFormat(apiMessage) {
  return {
    ...messageStruct.message,
    id: apiMessage.index,
    role: apiMessage.message.role,
    content: apiMessage.message.content,
    timestamp: new Date().getTime(),
  };
}

export async function sendMessages(messages) {
  console.log("AI", "Sending Chats", messages);
  messages = messages.filter((it) => it.role != "item");
  const apiResponse = await generateTextFromGpt(messages.map(toApiFormat));
  console.log("AI", "Response", apiResponse);
  const responseMessage = apiResponse; // assumes there is always at least one choice
  const parsedMessage = fromApiFormat(responseMessage);

  // Parse the content of the AI's response
  try {
    // const parsedContent = parseResponseContent(parsedMessage.content);
    const { cleanedResponse, info } = processResponse(parsedMessage.content);
    if (cleanedResponse) parsedMessage.content = cleanedResponse;
    console.log("AI", "Parsed", cleanedResponse, info);
    // Update the store with the parsed content
    vuex.dispatch("ai/setInfos", info);
  } catch (error) {
    console.log("AI", "Parsed Error", error);
  }

  return parsedMessage;
}

function processResponse(response) {
  // if (!response.includes("###")) return {};

  // The starting index of the special string
  let startIndex = response.indexOf("###") + 3;

  // The ending index of the special string
  let endIndex = response.indexOf("###", startIndex);

  // Log the start and end indexes
  console.log("Start index:", startIndex, "End index:", endIndex);

  // If endIndex is -1 (meaning "***" was not found), set endIndex to the length of the response
  if (endIndex === -1) endIndex = response.length;

  // Extract the special string
  let specialString = response.slice(startIndex, endIndex);

  // Clean the special string of any leading/trailing white spaces or other characters
  specialString = specialString.trim();

  // Remove the special string from the response
  let cleanedResponse =
    response.slice(0, startIndex - 3) + response.slice(endIndex + 3);

  // Split the special string into an array by comma
  let splittedString = specialString.split(";");

  console.log("Cleaned:", splittedString, cleanedResponse);
  // Construct the information object
  let info = {
    type: splittedString[0],
    operation: splittedString[1],
    params: JSON.parse(splittedString[2]), // If the third element is a JSON string
  };

  console.log("INFOS:", info);
  return { cleanedResponse, info };
}
