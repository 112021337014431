import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import { Type } from "../Types";
import Struct from "./datas/basedata.json";
import SmallStruct from "./datas/basedataSmall.json";

// nur ne Überlegung for future inport
export const BasedataStruct = Struct;

export class BasedataSimple {
  constructor(basedata) {
    Object.assign(this, SmallStruct);
    if (basedata) {
      const keys = Object.keys(SmallStruct);
      const keyLength = keys.length;
      for (let index = 0; index < keyLength; index++) {
        const key = keys[index];
        this[key] = basedata[key] || null;
      }
    }
  }

  // Oder geht this? mit nur Simple?
  getSimple() {
    const simple = new BasedataSimple(this);
    console.log(simple);
    return simple;
  }

  toSaveable() {
    let item = { ...cloneDeep(this) };
    delete item.type;
    return item;
  }
}

export default class Basedata extends BasedataSimple {
  constructor(basedata) {
    super(basedata);
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (basedata) {
      Object.assign(this, basedata);
    }
    this.type = new Type("basedata");
  }

  // DB
  toSaveable() {
    let item = { ...cloneDeep(this) };
    delete item.type;
    return item;
  }

  async add(onAdd) {
    let item = { ...this.toSaveable() };
    item = await firebase.addItem(this.type.key, item, onAdd);
    this.id = item.id;
    this.created = item.created;
    return item;
  }

  async save() {
    let item = { ...this.toSaveable() };
    item = await firebase.setItem(this.type.key, item);
    return item;
  }
  delete() {}
}
