import MTypes from "./typedefs";
import firebase from "../db/firebase/MFirebase";
import { currentCompId } from "../store/modules/companyModule";

export const Types = MTypes.entries;
export const TypeKeys = Object.keys(MTypes.entries);
export const TypesAddable = [];
export const TypesFavs = [];
export const TypesOther = [];

Object.values(Types).forEach((value) => {
  if (value.isAddable) TypesAddable.push(value);
  if (value.isFav) TypesFavs.push(value);
  else TypesOther.push(value);
});

export function findType(type) {
  for (const key of Object.keys(MTypes.entries)) {
    // Different Types
    const mItem = MTypes.entries[ key ];
    if (
      key === type ||
      mItem.router?.path === type ||
      // Type Keys -> Values
      Object.keys(mItem).some((key) => mItem[ key ] == type)
    ) {
      return mItem;
    }
  }
  return null;
}

export class Type {
  constructor(type) {
    if (type instanceof Object) {
      Object.assign(this, type);
      return;
    }

    let foundType = Types[ type ] ? Types[ type ] : findType(type);
    if (foundType) Object.assign(this, foundType);
  }

  link(id) {
    return "/c/" + currentCompId + this.router.path + id;
  }
}

export default class DataType {
  constructor(type) {
    const foundType = findType(type);

    Object.assign(this, foundType);

    this.searchObj = {
      item: undefined,
      items: [],
    };
  }

  async search(term, key, limit) {
    let array;
    if (term)
      array = await firebase.getItemsBy(this.db, term, key, false, limit, true);
    else {
      array = await firebase.getCollection(this.db, limit);
    }
    return array;
  }
}

export const todoTypes = {
  todo: new Type("todos"),
  group: new Type("todoGroups"),
};
