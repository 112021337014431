<template>
  <base-dialog
    dialKey="form"
    width="40vw"
    min-width="600px"
    scrollable
    :overlay="true"
    :title="form.title"
    :icon="form.icon"
  >
    <template #text>
      <div class="vertical gapped" style="padding: 1rem 0">
        <div
          class="horizontal gapped half"
          v-for="(entry, index) in form.entries"
          :key="entry.title + index"
        >
          <!-- Text and Number Input -->
          <v-text-field
            v-if="entry.type === 'String' || entry.type === 'Number'"
            :type="entry.type === 'Number' ? 'number' : 'text'"
            :name="entry.title"
            :label="entry.title"
            :id="entry.title"
            v-model="entry.value"
            color="onBack"
            hide-details
            rounded
            outlined
            background-color="#fefefe33"
          >
            <template #append>
              <span v-if="entry.unit" style="margin: 2.5px 0 0 0">
                {{ entry.unit }}
              </span>
            </template>
          </v-text-field>

          <!-- Checkboxes -->
          <div
            class="vertical gapped half"
            v-else-if="entry.type === 'Checkbox'"
            style="width: 100%"
          >
            <v-divider v-if="index > 0"></v-divider>
            <h4>{{ entry.title }}</h4>
            <div class="horizontal gapped wrapped">
              <v-checkbox
                v-for="checkboxEntry in entry.entries"
                :key="checkboxEntry"
                :label="checkboxEntry"
                v-model="entry.value"
                :value="checkboxEntry"
                color="onBack"
                hide-details
                rounded
                outlined
              ></v-checkbox>
            </div>
          </div>

          <v-divider v-else style="width: 100%" />
        </div>
      </div>
    </template>
  </base-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import BaseDialog from "../../../views/dialogs/BaseDialog.vue";
export default {
  components: { BaseDialog },
  name: "FormDialog",

  computed: {
    ...mapGetters("dialog", ["getDialogData"]),

    form() {
      return this.getDialogData("form");
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
