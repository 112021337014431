import Vue from "vue";
import VueRouter from "vue-router";
import Store, { modules } from "../store/index";
import routes from "./routes";

Vue.use(VueRouter);

export let wantedRoute = null;

export let onBack = undefined;
export function setOnBack(fun) {
  onBack = fun;
}

const router = new VueRouter({
  routes,
});

export const isSetupRoute = (route) => {
  return route.includes("setup") || route.includes("login");
};

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

router.beforeEach(async (to, from, next) => {
  console.log(Store, modules);
  if (window.popStateDetected) {
    window.popStateDetected = false;
    if (onBack) {
      if (onBack()) {
        onBack = undefined;
        next(false);
        return;
      }
    }
  }

  if (!to.params.compId) to.params.compId = from.params.compId;

  // Project
  Store.dispatch("appbar/setMeta", to.meta);
  Store.dispatch("appbar/setParams", to.params);

  console.log("Checking to", to, to.params);
  // checkProject(to);

  next();

  wantedRoute = null;
  return;
});

// async function checkProject(to) {
//   if (to.path.includes("/project/")) {
//     let proj = await Store.dispatch("project/setProjectID", to.params.pID);
//     Store.dispatch("setCurrentItem", proj);
//   } else {
//     Store.dispatch("project/resetState");
//   }
// }

export default router;
