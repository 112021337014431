import breads from "../../router/breads";

const mBreads = breads;

export default {
  namespaced: true,
  name: "appbar",

  state: {
    // View
    mode: 1,
    breads: [],
    isSetup: false,

    // Current Setted
    hideFrame: false,
    hideTop: false,
    hideSide: false,

    hideLeft: false,
    hideRight: false,

    // Disabled, by route etc
    disableFrame: false,
    disableTop: false,
    disableSide: false,

    disableSideSwitch: false,
    hideAppBarActions: false,

    // Datas
    currentSideList: [],
  },

  mutations: {
    setMode(state, mode) {
      state.mode = mode;
    },

    setIsSetup(state, isSetup) {
      state.isSetup = isSetup;
    },

    setHideFrame(state, value) {
      console.log("Set hide frame", value);
      state.hideFrame = value;
    },

    setHideTop(state, value) {
      state.hideTop = value;
    },

    setHideSide(state, value) {
      state.hideSide = value;
    },

    setHideLeft(state, value) {
      state.hideLeft = value;
    },
    setHideRight(state, value) {
      state.hideRight = value;
    },

    setDisableFrame(state, value) {
      console.log("Set hide frame", value);
      state.disableFrame = value;
    },

    setDisableTop(state, value) {
      state.disableTop = value;
    },

    setDisableSide(state, value) {
      state.disableSide = value;
    },

    setDisableSideSwitch(state, value) {
      state.disableSideSwitch = value;
    },

    setHideAppBarActions(state, value) {
      state.hideAppBarActions = value;
    },

    setBreads(state, value) {
      state.breads = value;
    },
  },

  actions: {
    setMeta(
      { commit },
      { hideFrame, hideSide, hideTop, disableSideBtn, breads, isSetup }
    ) {
      commit("setIsSetup", isSetup || false);
      commit("setDisableFrame", hideFrame || false);
      commit("setDisableTop", hideTop || false);
      commit("setDisableSide", hideSide || false);
      commit("setDisableSideSwitch", disableSideBtn || false);
      commit("setBreads", breads || []);
    },
    setParams() {
      // commit("setParams", params);
      console.todo("AppBar", "SetParams");
    },

    setHideFrame({ commit }, value) {
      console.log("Setting hideFrame", value);
      commit("setHideFrame", value);
    },
    toggleHideFrame({ commit, state }) {
      const newValue = !state.hideFrame;
      console.log("Toggling hideFrame", newValue);
      commit("setHideFrame", newValue);
    },
    setHideTop({ commit }, value) {
      console.log("Setting hideTop", value);
      commit("setHideTop", value);
    },
    toggleHideTop({ commit, state }) {
      const newValue = !state.hideTop;
      console.log("Toggling hideTop", newValue);
      commit("setHideTop", newValue);
    },
    setHideSide({ commit }, value) {
      console.log("Setting hideSide", value);
      commit("setHideSide", value);
    },
    toggleHideSide({ commit, state }) {
      const newValue = !state.hideSide;
      console.log("Toggling hideSide", newValue);
      commit("setHideSide", newValue);
    },

    setHideLeft({ commit }, value) {
      console.log("Setting hideLeft", value);
      commit("setHideLeft", value);
    },
    toggleHideLeft({ commit, state }) {
      const newValue = !state.hideLeft;
      console.log("Toggling hideLeft", newValue);
      commit("setHideLeft", newValue);
    },

    setHideRight({ commit }, value) {
      console.log("Setting hideRight", value);
      commit("setHideRight", value);
    },
    toggleHideRight({ commit, state }) {
      const newValue = !state.hideRight;
      console.log("Toggling hideRight", newValue);
      commit("setHideRight", newValue);
    },

    setDisableFrame({ commit }, value) {
      console.log("Setting DisableFrame", value);
      commit("setDisableFrame", value);
    },
    toggleDisableFrame({ commit, state }) {
      const newValue = !state.disableFrame;
      console.log("Toggling DisableFrame", newValue);
      commit("setDisableFrame", newValue);
    },
    setDisableTop({ commit }, value) {
      console.log("Setting DisableTop", value);
      commit("setDisableTop", value);
    },
    toggleDisableTop({ commit, state }) {
      const newValue = !state.disableTop;
      console.log("Toggling DisableTop", newValue);
      commit("setDisableTop", newValue);
    },
    setDisableSide({ commit }, value) {
      console.log("Setting DisableSide", value);
      commit("setDisableSide", value);
    },
    toggleDisableSide({ commit, state }) {
      const newValue = !state.disableSide;
      console.log("Toggling DisableSide", newValue);
      commit("setDisableSide", newValue);
    },
    setDisableSideSwitch({ commit }, value) {
      console.log("Setting disableSideSwitch", value);
      commit("setDisableSideSwitch", value);
    },
    toggleDisableSideSwitch({ commit, state }) {
      const newValue = !state.disableSideSwitch;
      console.log("Toggling disableSideSwitch", newValue);
      commit("setDisableSideSwitch", newValue);
    },
    setBreads({ commit }, value) {
      commit("setBreads", value);
    },
    setMode({ commit }, mode) {
      commit("setMode", mode);
    },

    addItem({ state, rootState }, item) {
      const addFunctions = {
        1: "addRecentItem",
        2: "addPinnedItem",
        3: "addBookmark",
      };

      const addFunction = addFunctions[state.mode];

      if (addFunction && rootState[addFunction]) {
        rootState[addFunction](item);
      }
    },

    removeItem({ state, rootState }, item) {
      const removeFunctions = {
        1: "removeRecentItem",
        2: "removePinnedItem",
        3: "removeBookmark",
      };

      const removeFunction = removeFunctions[state.mode];

      if (removeFunction && rootState[removeFunction]) {
        rootState[removeFunction](item);
      }
    },
  },

  getters: {
    isSetup: (state) => state.isSetup,
    getHideTop: (state) =>
      state.disableFrame ||
      state.hideFrame ||
      state.disableTop ||
      state.hideTop,

    getHideSide: (state) =>
      state.disableFrame ||
      state.hideFrame ||
      state.disableSide ||
      state.hideSide,

    getHideLeft: (state) =>
      state.disableFrame ||
      state.hideFrame ||
      state.disableSide ||
      state.hideSide ||
      state.hideLeft,

    getHideRight: (state) =>
      state.disableFrame ||
      state.hideFrame ||
      state.disableSide ||
      state.hideSide ||
      state.hideRight,

    getBreads(state, getters, rootState) {
      const { breads } = state;
      if (!breads) return [];

      if (typeof breads === "function") {
        return breads.call(
          this,
          rootState.currentItem?.title || rootState.currentItem?.id
        );
      }
      mBreads;
      console.log("Got Breads", breads);
      if (breads.length === 0) return [mBreads.dash];
      return breads;
    },

    getCurrentSideList: (state, getters, rootState) => {
      const listMap = {
        1: rootState.recentItems,
        2: rootState.pinnedItems,
        3: rootState.bookmarks,
      };

      return listMap[state.mode] || [];
    },
  },
};
