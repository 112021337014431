//

import firebase, { setMiUser } from "../../db/firebase/MFirebase";

import MiUser from "../../types/user/MiUser";
// import router from "../../router";

export const USER_SERVICE = {
  MiSolar: "misolar",
  Google: "google",
};

let listener;

export default {
  namespaced: true,
  name: "miuser",

  state: {
    uID: null,
    service: USER_SERVICE.MiSolar,
    user: null,
    userData: null,
    permissions: [],
    loading: {},
    error: null,
    uLoaded: false,
    listenerData: null,
  },

  mutations: {
    setError(state, error) {
      console.log("Setting Error", error);
      state.error = error;
    },

    setLoaded(state, loaded) {
      state.uLoaded = loaded;
    },

    setUser(state, user) {
      if (user && !(user instanceof MiUser)) {
        user = new MiUser(user);
      }
      console.log("Set User store user", user);
      state.user = user;
      state.service = user?.service || undefined;
      state.uID = user?.uid || user?.id || undefined;
      if (user && state.service) user.service = state.service;
      state.uLoaded = true;
    },

    addCompany(state, company) {
      console.log("Setting company", company, state.user);
      state.user.companies[company.id] = company;
    },
    setUserService(state, service) {
      console.log("Set store userservice", service);
      state.service = service;
      if (state.user) state.user.service = service;
    },
    setUserTheme(state, theme) {
      state.user.styles.currentTheme = theme;
    },
    setUserThemes(state, themes) {
      state.user.styles.themes = themes;
    },
    setPermissions({ state }, permissions) {
      state.permissions = permissions;
    },
    setUserTags(state, tags) {
      state.user.tags = tags;
    },
    addUserTag(state, tag) {
      state.user.tags.push(tag);
    },
    removeUserTag(state, tag) {
      const index = state.user.tags.indexOf(tag);
      if (index !== -1) {
        state.user.tags.splice(index, 1);
      }
    },
  },

  actions: {
    setULoaded({ commit }, loaded) {
      commit("setLoaded", loaded);
    },

    listenUser({ dispatch }) {
      console.log("User Module", "Set user listener");
      if (listener) listener();
      listener = firebase.auth.listenToUser(
        async (user) => {
          console.log("User Module", "User changed", user);

          if (user) {
            let userData = await firebase.userData.get(user.uid);
            if (!userData) firebase.userData.set(user.toSaveable());

            user = new MiUser({ ...user, ...userData });
          }

          user = setMiUser(user);
          dispatch("setUser", user);
          dispatch("listenUserData");
        },
        (err) => {
          console.log(err);
          dispatch("setUser", null);
        }
      );
      return listener;
    },

    async listenUserData({ state, dispatch }) {
      console.log("Set UserData listener");
      if (state.listenerData) state.listenerData();
      state.listenerData = firebase.miUser.listenToChanges((found) => {
        console.log("UserData CHANGED", found);
        dispatch("setUser", found);
      });
    },

    async saveUser({ dispatch }, user) {
      console.log("Saving Userdata", user);
      if (user.toSaveable) user = user.toSaveable();
      await firebase.updateMiUser(user);

      dispatch("setUser", user);
    },

    setUser({ commit }, user) {
      commit("setUser", user);
    },

    // Tags
    setUserTags({ commit }, tags) {
      commit("setUserTags", tags);
      updateUser({ tags: tags });
    },
    addUserTag({ commit, state }, tag) {
      if (!state.user.tags.includes(tag)) {
        commit("addUserTag", tag);
        updateUser({ tags: state.user.tags });
      }
    },
    removeUserTag({ commit, state }, tag) {
      if (state.user.tags.includes(tag)) {
        commit("removeUserTag", tag);
        updateUser({ tags: state.user.tags });
      }
    },

    setUserDefault({ state, commit }, idObj) {
      if (!state.user) return console.log("No user for default");
      let ids = {
        ...state.user.defaults,
        ...idObj,
      };
      let user = {
        ...state.user,
        defaults: ids,
      };
      commit("setUser", user);
      firebase.updateMiUser({ defaults: ids });
    },

    setUserService({ commit }, service) {
      commit("setUserService", service);
    },

    setUserTheme({ commit, state }, theme) {
      commit("setUserTheme", theme);
      firebase.updateMiUser({
        styles: {
          ...state.user.styles,
          currentTheme: theme,
        },
      });
    },

    setUserThemes({ commit, state }, themes) {
      commit("setUserThemes", themes);
      firebase.updateMiUser(state.uid, {
        styles: {
          ...state.user.styles,
          themes: {
            ...state.user.styles.themes,
            ...themes,
          },
        },
      });
    },

    setPermissions({ commit }, permissions) {
      commit("setPermissions", permissions);
    },

    addCompanyToUser({ commit }, company) {
      commit("addCompany", company);
    },

    async createUser({ commit, dispatch }, { user, pw }) {
      if (!(user instanceof MiUser)) throw "User not MiUser";
      await firebase.auth.createWithEmail(
        user.email,
        pw,
        (miUser) => {
          user.id = miUser.uid;
          user.add();
          if (user) return;
          dispatch("setUser", user);
        },
        (error) => {
          commit("setError", error);
        }
      );
    },
  },

  getters: {
    getUser: (state) => state.user,
    getUserName: (state) => state.user.title || state.user.prename,
    getUserThemes: (state) => state.user.styles.themes,
    getUserTags: (state) => state.user.tags,

    loading: (state) => {
      return state.loading;
    },

    img: (state) => {
      if (!state || !state.imgUrl || !state.img) return;
      return state.imgURL || state.img || { empty: true };
    },

    hasPermission: (state) => (permission) => {
      return state.permissions.includes(permission);
    },

    complete: (state) => state.user && state.userData,
    uLoaded: (state) => state.uLoaded,
  },
};

function updateUser(item) {
  firebase.updateMiUser(item);
}
