/** @format */

import { Type, Types } from "../../types/Types";
import MiUser from "../../types/user/MiUser";
import Company from "../../types/company/company";

// Firebase
import FireConfig from "./config.json";
import {
  basePaths,
  devFeedbackPath,
  devNewVersionPath,
  devVersionPath,
  getPaths,
} from "./MFirepaths";

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Auth
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

// Store
import {
  where,
  limit,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  CACHE_SIZE_UNLIMITED,
} from "@firebase/firestore";

// Initialize FirebaseApp
const app = initializeApp(FireConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const store = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  }),
});

//
// Helper
// Nur Fire* funs bumms
//
import StoreHelper from "./subs/MFirestore";
const storeHelper = new StoreHelper(store);

import AuthHelper from "./subs/MFireAuth";
const authHelper = new AuthHelper(auth);

import StorageHelper from "./subs/MFireStorage";
import Project from "../../types/project/project";
import Customer from "../../types/customer/customer";
import BasedataGroup from "../../types/basedata/basedataGroup";
import Basedata from "../../types/basedata/basedata";
const storageHelper = new StorageHelper(storage);

// Current User???
// Dont like it that way
export var miUser;
export var initUser = false;
export var miUserPath;

export function setMiUser(user) {
  if (user && !(user instanceof MiUser)) {
    user = new MiUser(user);
  }
  miUser = user;
  initUser = true;
  miUserPath = user ? "/users/" + user.id : undefined;
  return user;
}

export function getSmallUser() {
  return {
    id: miUser.id,
    title: miUser.title,
  };
}

export function setTimes(item) {
  // Set Times
  if (!item.created)
    item.created = {
      time: new Date().getTime(),
      user: miUser?.toSmall() || {
        id: "-1",
        title: "N/A",
      },
    };

  item.updated = {
    time: new Date().getTime(),
    user: miUser?.toSmall() || {
      id: "-1",
      title: "N/A",
    },
  };
}

let paths = {};
export var currentCompanyID;
export var initCompany = false;

export function setCurrentCompanyID(id) {
  paths = getPaths(id, miUser.id);
  currentCompanyID = id;
  initCompany = true;
}

setPersistence(auth, browserLocalPersistence).catch((error) => {
  // Handle Errors here.
  const errorCode = error.code;
  const errorMessage = error.message;
  console.error(errorCode, errorMessage);
});

class MyFirebase {
  constructor() { }

  /**
   *  Google Auth
   * Logins, Users, etc
   */
  auth = {
    // G-Popup
    login(onFound, onError) {
      return authHelper.loginWithGoogle(onFound, onError);
    },

    // Custom
    loginWithEmail(email, pw, onFound, onError) {
      return authHelper.loginWithEmail(email, pw, onFound, onError);
    },

    // Create Account
    createWithEmail(email, pw, onFound, onError) {
      return authHelper.createWithEmail(email, pw, onFound, onError);
    },

    // ListenToUser
    listenToUser(onChanged, onError) {
      authHelper.listenToUser(async (user) => {
        if (!user) {
          onChanged();
          return;
        }

        let miuser = new MiUser({
          ...user,
        });
        onChanged(miuser);
        return miuser;
      }, onError);
    },

    // Logout
    logout() {
      auth.signOut();
    },
  };

  miUser = {
    listenToChanges(onFound, onError) {
      if (!miUser) throw "No User";
      return storeHelper.listenToOne(
        "/users/" + miUser.id,
        onFound,
        [],
        onError,
        MiUser
      );
    },
  };

  /**
   *  Extra UserData for Fireuser
   */
  userData = {
    async set(item) {
      return await storeHelper.set("/users/" + item.id, item);
    },

    async get(id) {
      return await storeHelper.getItem("/users/", id);
    },

    async update(item) {
      return await storeHelper.update("/users/", item);
    },

    getFavorites() {
      return miUser.favorites;
    },

    setRecent(items) {
      return storeHelper.update(miUserPath, {
        favorites: {
          ...miUser.favorites,
          recents: items.map((it) => (it.toSaveable ? it.toSaveable() : it)),
        },
      });
    },

    setBookmarks(items) {
      return storeHelper.update(miUserPath, {
        favorites: {
          ...miUser.favorites,
          bookmarks: items.map((it) => (it.toSaveable ? it.toSaveable() : it)),
        },
      });
    },

    setPinned(items) {
      return storeHelper.update(miUserPath, {
        favorites: {
          ...miUser.favorites,
          pinned: items.map((it) => (it.toSaveable ? it.toSaveable() : it)),
        },
      });
    },
  };

  /**
   *  Worker
   */
  worker = {
    getWorker(id) {
      return storeHelper.getItem(paths.users, id, MiUser);
    },

    async saveWorker(miuser) {
      if (isNaN(miuser.id)) {
        if (miuser.id) miuser.uid = miuser.id;
        let count = await storeHelper.getCountsFor(paths.users, []);
        miuser.id = count + 1;
      }
      storeHelper.set(
        paths.user(miuser.id),
        miuser.toSaveable ? miuser.toSaveable() : miuser
      );
      return miuser;
    },
  };

  /**
   *  Company
   */
  company = {
    /**
     * Creates a Company.
     *
     * @param {Company | Object} company
     * @returns {Company} Created Company with ID
     */
    createCompany(company) {
      return storeHelper.add(
        basePaths.company,
        company.toSaveable ? company.toSaveable() : company
      );
    },

    /**
     *  Gets a Company by ID.
     *
     * @param {String | Number} companyID
     * @returns {Object} Companydata
     */
    getCompany(companyID) {
      return storeHelper.getItem(basePaths.company, companyID);
    },

    /**
     *  Get companies.
     *
     * @param {String} uID
     * @param {String | Number} compIDs
     * @returns Object with { 'compID' : 'Company' }
     */
    getCompanies(uID, compIDs) {
      return storeHelper.getCompanies(uID, compIDs);
    },

    /**
     * Save Company.
     *
     * @param {Company | Object} company
     * @returns {Company} Created Company with ID
     */
    saveCompany(company) {
      return storeHelper.set(paths.company, company);
    },

    update(companyUpdates) {
      if (!companyUpdates.id) throw "Updates need ID of Company";
      return storeHelper.update(
        basePaths.company + "/" + companyUpdates.id,
        companyUpdates
      );
    },

    /**
     *
     * @param {String | Number} compid Company ID
     * @param {Function} onChanged Callback found(item)
     * @param {Function} onError Callback error
     * @returns {Unsubscribe} Listener as Unsub-Function
     */
    listenToCompany(compid, onChanged, onError) {
      return storeHelper.listenToDoc(
        basePaths.company + "/" + compid,
        onChanged,
        [],
        onError,
        Company
      );
    },
  };

  /**
   *  Storage
   */
  storage = {
    getURL(itemPath) {
      return storageHelper.getURL(itemPath);
    },

    uploadFile(itemId, file, onUpload, onError) {
      return storageHelper.uploadFileToCompany(
        paths.basedata(itemId) + "/" + file.name,
        file,
        onUpload,
        onError
      );
    },
  };

  //
  // Types
  //
  /**
   * Projects
   */
  project = {
    /**
     * Add a Project.
     *
     * @param {Project | Object} item - Project data to be added.
     * @returns {Promise} Promise that resolves when the project is added.
     */
    add(item) {
      if (!item.id)
        return storeHelper.add(
          paths.projects,
          item.toSaveable ? item.toSaveable() : item
        );
    },

    /**
     * Set a Project.
     *
     * @param {Project | Object} item - Project data to be set.
     * @returns {Promise} Promise that resolves when the project is set.
     */
    set(item) {
      return storeHelper.set(
        paths.project(item.id),
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Update a Project.
     *
     * @param {Project | Object} item - Project data to be updated.
     * @returns {Promise} Promise that resolves when the project is updated.
     */
    update(id, updates) {
      return storeHelper.update(paths.project(id ? id : updates.id), updates);
    },

    /**
     * Get a Project by ID.
     *
     * @param {String | Number} pID - ID of the project to retrieve.
     * @returns {Promise} Promise that resolves with the project data.
     */
    get(searchParams) {
      if (
        typeof searchParams === "string" ||
        typeof searchParams === "number"
      ) {
        // by ID
        return storeHelper.getItem(paths.project(searchParams));
      }
      return storeHelper.getItemBy(paths.projects, searchParams, Project);
    },

    /**
     * Listen to changes for a specific Project.
     *
     * @param {String | Number} pID - ID of the project to listen to.
     * @param {Function} onFound - Callback function triggered when the project is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listen(pID, onFound, extras, onError) {
      return storeHelper.listenTo(paths.project(pID), onFound, extras, onError);
    },

    /**
     * Listen to changes for all Projects.
     *
     * @param {Function} onFound - Callback function triggered when a project is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listenAll(onFound, extras, onError) {
      return storeHelper.listenTo(
        paths.projects,
        onFound,
        extras,
        onError,
        Project
      );
    },
  };

  /**
   * Customer
   */
  customer = {
    /**
     * Add a Customer.
     *
     * @param {Customer | Object} item - Customer data to be added.
     * @returns {Promise} Promise that resolves when the customer is added.
     */
    add(item) {
      return storeHelper.add(
        paths.customers,
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Set a Customer.
     *
     * @param {Customer | Object} item - Customer data to be set.
     * @returns {Promise} Promise that resolves when the customer is set.
     */
    set(item) {
      return storeHelper.set(
        paths.customer(item.id),
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Update a Customer.
     *
     * @param {Customer | Object} item - Customer data to be updated.
     * @returns {Promise} Promise that resolves when the customer is updated.
     */
    update(id, updates) {
      return storeHelper.update(paths.customer(id ? id : updates.id), updates);
    },

    /**
     * Get a Customer by ID.
     *
     * @param {String | Number} cID - ID of the customer to retrieve.
     * @param {Function} onFound - Callback function triggered when the customer is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Promise} Promise that resolves with the customer data.
     */
    get(cID, onFound, extras, onError) {
      return storeHelper.getItem(
        paths.customer(cID),
        onFound,
        extras,
        onError,
        Customer
      );
    },

    /**
     * Listen to changes for a specific Customer.
     *
     * @param {String | Number} cID - ID of the customer to listen to.
     * @param {Function} onFound - Callback function triggered when the customer is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listen(cID, onFound, extras, onError) {
      return storeHelper.listenTo(
        paths.customer(cID),
        onFound,
        extras,
        onError,
        Customer
      );
    },
  };

  /**
   * Supplier
   */
  supplier = {
    /**
     * Add a Supplier.
     *
     * @param {Supplier | Object} item - Supplier data to be added.
     * @returns {Promise} Promise that resolves when the supplier is added.
     */
    add(item) {
      return storeHelper.add(
        paths.suppliers,
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Set a Supplier.
     *
     * @param {Supplier | Object} item - Supplier data to be set.
     * @returns {Promise} Promise that resolves when the supplier is set.
     */
    set(item) {
      return storeHelper.set(
        paths.supplier(item.id),
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Update a Supplier.
     *
     * @param {Supplier | Object} item - Supplier data to be updated.
     * @returns {Promise} Promise that resolves when the supplier is updated.
     */
    update(id, updates) {
      return storeHelper.update(paths.supplier(id ? id : updates.id), updates);
    },

    /**
     * Get a Supplier by ID.
     *
     * @param {String | Number} sID - ID of the supplier to retrieve.
     * @returns {Promise} Promise that resolves with the supplier data.
     */
    get(sID) {
      return storeHelper.getItem(paths.suppliers, sID);
    },

    /**
     * Listen to changes for a specific Supplier.
     *
     * @param {String | Number} sID - ID of the supplier to listen to.
     * @param {Function} onFound - Callback function triggered when the supplier is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listen(sID, onFound, extras, onError) {
      return storeHelper.listenTo(
        paths.supplier(sID),
        onFound,
        extras,
        onError
      );
    },
  };

  /**
   * Basedata
   */
  basedata = {
    /**
     * Add Basedata.
     *
     * @param {Basedata | Object} item - Basedata to be added.
     * @returns {Promise} Promise that resolves when the basedata is added.
     */
    add(item) {
      return storeHelper.add(
        paths.basedatas,
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Set Basedata.
     *
     * @param {Basedata | Object} item - Basedata to be set.
     * @returns {Promise} Promise that resolves when the basedata is set.
     */
    set(item) {
      return storeHelper.set(
        paths.basedata(item.id),
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Update a Basedata.
     *
     * @param {Basedata | Object} item - Basedata data to be updated.
     * @returns {Promise} Promise that resolves when the basedata is updated.
     */
    update(id, updates) {
      return storeHelper.update(paths.basedata(id ? id : updates.id), updates);
    },

    /**
     * Get Basedata by ID.
     *
     * @param {String | Number} sID - ID of the basedata to retrieve.
     * @returns {Promise} Promise that resolves with the basedata data.
     */
    get(sID) {
      return storeHelper.getItem(paths.basedatas, sID, Basedata);
    },

    /**
     * Listen to changes for a specific Basedata.
     *
     * @param {String | Number} sID - ID of the basedata to listen to.
     * @param {Function} onFound - Callback function triggered when the basedata is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listen(sID, onFound, extras, onError) {
      return storeHelper.listenTo(
        paths.basedata(sID),
        onFound,
        extras,
        onError,
        Basedata
      );
    },
  };

  /**
   * BasedataGroup
   * is a single doc in /extras/
   */
  basedataGroup = {
    /**
     * Set BasedataGroup.
     *
     * @param {BasedataGroup | Object} item - BasedataGroup to be set.
     * @returns {Promise} Promise that resolves when the basedata is set.
     */
    set(item) {
      return storeHelper.set(
        paths.basedataGroups,
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Update a BasedataGroup.
     *
     * @param {BasedataGroup | Object} item - BasedataGroup data to be updated.
     * @returns {Promise} Promise that resolves when the basedata is updated.
     */
    update(updates) {
      return storeHelper.update(paths.basedataGroups, {
        ...updates,
        entries: updates.entries.map((it) =>
          it.toSaveable ? it.toSaveable() : it
        ),
      });
    },

    /**
     * Listen to changes for a specific BasedataGroup.
     *
     * @param {String | Number} sID - ID of the BasedataGroup to listen to.
     * @param {Function} onFound - Callback function triggered when the BasedataGroup is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listen(onFound, onError, onNotExists) {
      console.log("Listen to bdGroup change", paths.basedataGroups);
      return storeHelper.listenToDoc(
        paths.basedataGroups,
        onFound,
        [],
        onError,
        BasedataGroup,
        onNotExists
      );
    },
  };

  /**
   * Todos
   */
  todo = {
    /**
     * Add a Todo.
     *
     * @param {Todo | Object} item - Todo data to be added.
     * @returns {Promise} Promise that resolves when the todo is added.
     */
    add(item) {
      return storeHelper.add(
        paths.todos,
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Set a Todo.
     *
     * @param {Todo | Object} item - Todo data to be set.
     * @returns {Promise} Promise that resolves when the todo is set.
     */
    set(item) {
      return storeHelper.set(
        paths.todo(item.id),
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Get a Todo by ID.
     *
     * @param {String | Number} todoID - ID of the todo to retrieve.
     * @returns {Promise} Promise that resolves with the todo data.
     */
    get(todoID) {
      return storeHelper.getItem(paths.todos, todoID);
    },

    /**
     * Listen to changes for all Todos.
     *
     * @param {Function} onFound - Callback function triggered when a todo is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listen(onFound, extras, onError) {
      return storeHelper.listenTo(paths.todos, onFound, extras, onError);
    },

    /**
     * Listen to changes for a specific Todo.
     *
     * @param {String | Number} todoID - ID of the todo to listen to.
     * @param {Function} onFound - Callback function triggered when the todo is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listenOne(todoID, onFound, extras, onError) {
      return storeHelper.listenToDoc(
        paths.todos,
        todoID,
        onFound,
        extras,
        onError
      );
    },
  };

  /**
   *  Notes
   */
  note = {
    /**
     * Add a Note.
     *
     * @param {Note | Object} item - Note data to be added.
     * @returns {Promise} Promise that resolves when the note is added.
     */
    add(item) {
      const itemClass = item.type.class;
      return new itemClass(
        storeHelper.add(paths.notes, item.toSaveable ? item.toSaveable() : item)
      );
    },

    /**
     * Set a Note.
     *
     * @param {Note | Object} item - Note data to be set.
     * @returns {Promise} Promise that resolves when the note is set.
     */
    set(item) {
      return storeHelper.set(
        paths.note(item.id),
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Get a Note by ID.
     *
     * @param {String | Number} noteID - ID of the note to retrieve.
     * @returns {Promise} Promise that resolves with the note data.
     */
    get(noteID) {
      return storeHelper.getItem(paths.notes, noteID);
    },

    /**
     * Listen to changes for all Notes.
     *
     * @param {Function} onFound - Callback function triggered when a note is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listen(onFound, extras, onError) {
      return storeHelper.listenTo(paths.notes, onFound, extras, onError);
    },

    /**
     * Listen to changes for a specific Note.
     *
     * @param {String | Number} noteID - ID of the note to listen to.
     * @param {Function} onFound - Callback function triggered when the note is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listenOne(noteID, onFound, extras, onError) {
      return storeHelper.listenToDoc(
        paths.notes,
        noteID,
        onFound,
        extras,
        onError
      );
    },
  };

  doc = {
    /**
   * Add a Note.
   *
   * @param {Note | Object} item - Note data to be added.
   * @returns {Promise} Promise that resolves when the note is added.
   */
    add(item) {
      const itemClass = item.type.class;
      return new itemClass(
        storeHelper.add(paths.docs, item.toSaveable ? item.toSaveable() : item)
      );
    },

    /**
     * Set a Note.
     *
     * @param {Note | Object} item - Note data to be set.
     * @returns {Promise} Promise that resolves when the note is set.
     */
    set(item) {
      return storeHelper.set(
        paths.docs,
        item.toSaveable ? item.toSaveable() : item
      );
    },

    /**
     * Get a Note by ID.
     *
     * @param {String | Number} noteID - ID of the note to retrieve.
     * @returns {Promise} Promise that resolves with the note data.
     */
    get(noteID) {
      return storeHelper.getItem(paths.docs, noteID);
    },

    /**
     * Listen to changes for all Notes.
     *
     * @param {Function} onFound - Callback function triggered when a note is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listen(onFound, extras, onError) {
      return storeHelper.listenTo(paths.docs, onFound, extras, onError);
    },

    /**
     * Listen to changes for a specific Note.
     *
     * @param {String | Number} noteID - ID of the note to listen to.
     * @param {Function} onFound - Callback function triggered when the note is found.
     * @param {Array} extras - Additional options for the listener.
     * @param {Function} onError - Callback function triggered on error.
     * @returns {Unsubscribe} Listener as an Unsub-Function.
     */
    listenOne(noteID, onFound, extras, onError) {
      return storeHelper.listenToDoc(
        paths.docs,
        noteID,
        onFound,
        extras,
        onError
      );
    },
  }

  // General Listeners
  listenToDev(onFound, extras, onError) {
    storeHelper.listenTo(devFeedbackPath, onFound, extras, onError);
  }

  async getOrAddUser() {
    let storeUser = await this.user.findInDB();
    if (!storeUser) storeUser = await this.user.addToDB();
    return new MiUser(storeUser);
  }

  // ****** ***** ******
  // ****** Store ******
  // ****** ***** ******

  // UserData
  async getUserData(user) {
    if (!user) return;
    return await storeHelper.getUserData(user.uid);
  }

  async createUserData(userData) {
    return await storeHelper.createUserData(this.user.uid, userData);
  }

  // Company
  async sendCompanyRequest(companyid, req) {
    req.answered = false;
    return await storeHelper.sendCompanyRequest(companyid, this.user.uid, req);
  }

  async checkInvite(companyid) {
    return await storeHelper.checkInvite(companyid, this.user.uid);
  }

  async getRequests(onFound, onError, extras) {
    return await storeHelper.getRequests(
      this.company.id,
      onFound,
      onError,
      extras
    );
  }

  async listenToRequest(compid, req, onFound, extras) {
    return await storeHelper.listenToDoc(
      "requests/" + compid + "/users/" + req.id,
      (found) => {
        onFound(found);
      },
      extras
    );
  }

  async applyRequest(req, asAdmin) {
    let changed = false;
    if (!this.company.users.find((it) => it == req.id)) {
      this.company.users.push(req.id);
      changed = true;
    }
    if (asAdmin && !this.company.admins.find((it) => it == req.id)) {
      this.company.admins.push(req.id);
      changed = true;
    }
    req.apply = true;
    req.answered = true;
    if (changed) this.saveData(true);
    return await storeHelper.setRequest(this.company.id, req);
  }

  async denyRequest(req) {
    req.apply = false;
    req.answered = true;
    return await storeHelper.setRequest(this.company.id, req);
  }

  async deleteRequest(req) {
    return await storeHelper.removeRequest(this.company.id, req);
  }

  async leaveCompany() {
    this.userData.companyid = null;
    this.saveData(false, true);
  }

  async uploadCompanyFile(path, file, onUpload, onError) {
    storageHelper.uploadFileToCompany(
      path,
      file,
      (upload) => {
        const segments = path.split("/");
        let item = this.company.files;
        segments.forEach((seg, i) => {
          if (!item[ seg ]) {
            if (i == segments.length - 1) {
              item[ seg ] = [];
            } else {
              item[ seg ] = {};
            }
          }
          item = item[ seg ];
        });
        item.push({
          path: upload.path,
          name: upload.name,
          type: upload.type,
        });
        this.saveCompany(this.company);
        onUpload(upload);
      },
      onError
    );
  }

  // Firestore
  listenTo(type, onFound, extras, onError) {
    const fullType = new Type(type);
    if (!fullType.db) {
      console.error("MFirebase listen to", type, extras);
      throw "No Type to listen to";
    }

    const listener = storeHelper.listenTo(
      paths[ fullType.db ],
      onFound,
      extras,
      onError,
      fullType.class || undefined
    );
    return listener;
  }

  listenToOne(type, id, onChanged, onError) {
    const fullType = new Type(type);
    if (!fullType.key) throw "No Type to listen to";

    const listener = storeHelper.listenToOne(
      paths[ fullType.key ](id),
      onChanged,
      onError,
      fullType.class || undefined
    );
    return listener;
  }

  async getProgressForProject(project) {
    let todosOpen = await this.getCountsFor("todos", [
      where("pID", "==", project.id),
      where("finished", "==", false),
    ]);

    let todosFinished = await this.getCountsFor("todos", [
      where("pID", "==", project.id),
      where("finished", "==", true),
    ]);

    let ges = todosFinished + todosOpen;

    return {
      open: todosOpen,
      finished: todosFinished,
      ges: ges,
      percent: ges <= 0 ? 0 : (todosFinished / ges).toFixed(2) * 100,
    };
  }

  async addItem(type, item, onFinish) {
    console.log("Adding Item", type, item);
    const fullType = new Type(type);

    // Count & ID
    // const currentItemCount = await this.getCountsFor(fullType.db);
    // if (!item.id) item.id = `${currentItemCount + 1}`;

    // Extra Fields
    item.search = {};

    fullType.searchKeys.forEach((it) => {
      let searchitem;
      try {
        if (isNaN(item[ it ])) {
          searchitem = item[ it ].toLowerCase();
        } else {
          searchitem = item[ it ];
        }
      } catch {
        //
      }
      if (searchitem) item.search[ it ] = searchitem;
    });

    if (item.toSaveable) item = item.toSaveable();

    // Adding
    storeHelper.add(paths[ fullType.key + "s" ], item).then(() => {
      // this.setCount(fullType.db);
      if (onFinish) onFinish(item);
    });

    return item;
  }

  async setCount(type, company, extras) {
    const count = await this.getCountsFor(type, extras);
    // if (!this.counts[type]) this.counts[type] = 0;
    // this.counts[type] = count;
    if (company) {
      company.counts[ type ] = count;
      return;
    }
    if (this.company.counts[ type ] != count) {
      this.company.counts[ type ] = count;
      return true;
    }
  }

  async getCountsFor(type, extras) {
    const fullType = new Type(type);
    return await storeHelper.getCountsFor(
      paths[ fullType.db ],
      extras ? extras : []
    );
  }

  async finishItem(type, item) {
    item.finished = true;
    this.setItem(type, item);
    this.addItemToCompany("f" + Types[ item.type ].db, item);
  }

  async setItem(type, item) {
    // this.addToCache(type, item, "w");
    const fullType = new Type(type);
    if (item.toSaveable) item = item.toSaveable();

    storeHelper.set(paths[ fullType.key ](item.id), item);
    return item;
  }

  async updateItem(type, item) {
    const fullType = new Type(type);
    return await storeHelper.update(paths[ fullType.key ](item.id), item);
  }

  async updateUser(uid, item) {
    return await storeHelper.update(paths.user(uid), item);
  }

  updateMiUser(item) {
    return storeHelper.update(miUserPath, item);
  }

  async getItem(type, id) {
    if (!type) throw "Type missing";
    if (!id) throw "ID missing";
    const fullType = new Type(type);
    console.log("Fulltypekey", type, paths, paths[ fullType.key ]);
    let item = await storeHelper.getItem(
      paths[ fullType.key ](id),
      undefined,
      fullType.class
    );
    return item;
  }

  async getItemBy(type, extras) {
    if (!type) throw "Type missing";
    if (!extras) throw "Extras missing";
    const fullType = new Type(type);
    let item = await storeHelper.getItem(paths[ fullType.db ], extras);
    return item;
  }

  async getCollection(type, mlimit, extras) {
    let fullType = new Type(type);
    console.log("Get Collection", type, paths[ fullType.db ], fullType);

    if (!extras) extras = [];
    if (mlimit) extras.push(limit(mlimit));

    let items = await storeHelper.getItems(paths[ fullType.db ], [ ...extras ]);
    return items.map((it) => (fullType.class ? new fullType.class(it) : it));
  }

  async getAll(type) {
    return await storeHelper.getItems(paths[ type ]);
  }

  async deleteItem(type, id) {
    const fullType = new Type(type);
    return await storeHelper.remove(paths[ fullType.db ], id);
  }

  async getItems(type, extras) {
    const fullType = new Type(type);
    return await storeHelper.getItems(paths[ fullType.db ], extras);
  }

  getItemsBy(type, searchString, searchKeys, getNext, limit, hideHeadlines) {
    if (type instanceof Type) type = type.db;

    return storeHelper.getItemsBy(
      paths[ type ],
      searchString,
      searchKeys,
      getNext,
      limit,
      hideHeadlines
    );
  }

  // Belege
  // addBeleg(beleg) {}

  // Save User & Company Data
  saveData(company, user) {
    if (company || this.edited.company) {
      // console.log("Saving Company");
      this.saveCompany(this.company);
      // console.log("Saved Company");
    }
    if (user || this.edited.userData) {
      // console.log("Saving UserData");
      storeHelper.set(this.userPath(), this.userData);
      // console.log("Saved UserData");
    }
  }

  nameOrEmail = () => {
    return this.user.displayName ? this.user.displayName : this.user.email;
  };

  // Dev
  async getLastVersion() {
    return await storeHelper.getItem(devVersionPath);
  }

  async getNewVersion() {
    return await storeHelper.getItem(devNewVersionPath);
  }

  async saveNewVersion(version) {
    storeHelper.set(devNewVersionPath, version);
  }

  submitNewVersion(message) {
    storeHelper.set(devVersionPath, message);
  }

  async getSubmittedVersion() {
    return await storeHelper.getItem(devVersionPath);
  }

  submitFeedback(feedback) {
    storeHelper.addToCollection(devFeedbackPath, feedback);
  }

  setFeedbackRead(feedback) {
    storeHelper.set(devFeedbackPath + feedback.id, {
      ...feedback,
      read: true,
    });
  }
}

const firebase = new MyFirebase();

// Listeners???????????????????????????????
export const listeners = {
  inits: {},
  entries: {},

  sub: (page, key, onFound, extras, onError) => {
    console.log("Sub to", page);
    const listenerEntry = {
      page: page,
      key: key,
      onFound: onFound,
      extras: extras,
    };

    // if (listeners.has(listenerEntry)) throw Error("Already exists");

    const listener = firebase.listenTo(key, onFound, extras, onError);
    listenerEntry.unsub = listener;

    if (listeners.entries[ page ]) listeners.entries[ page ].push(listenerEntry);
    else listeners.entries[ page ] = [ listenerEntry ];
  },

  unsub: (page) => {
    if (page) {
      if (!listeners.entries[ page ]) return;
      listeners.entries[ page ].forEach((it) => it.unsub());
      listeners.entries[ page ] = [];
    } else
      Object.keys(listeners.entries).forEach((page) => listeners.unsub(page));
  },

  has: (obj) => {
    if (!listeners.entries[ obj.page ]) return false;
    // DOESNT WORK!!!!!!;
    return listeners.entries[ obj.page ].find((it) => {
      console.log(
        it == obj,
        it,
        obj,
        it.key == obj.key,
        it.onFound == obj.onFound,
        it.extras == obj.extras
      );
      return (
        it.key == obj.key &&
        it.onFound == obj.onFound &&
        it.extras == obj.extras
      );
    });
  },
};

export default firebase;
